<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>

    <creationAssembly2
      :content="content"
      @getContent="getContent1"
      :disabled="disabled"
    ></creationAssembly2>

    <creationAssembly2
      :content="content2"
      @getContent="getContent2"
      :disabled="disabled"
    ></creationAssembly2>
  </div>
</template>
<script>
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    creationAssembly2,
  },
  data() {
    return {
      title: "工作计划",

      content: {
        title: "岗位",
        placeholder: this.pageContent.station,
      },
      content2: {
        title: "工作内容",
        placeholder: this.pageContent.content,
      },
      //   请求信息
      contentInfo: {
        content: "",
        station: "",
      },
    };
  },
  created() {},
  methods: {
    getContent1(i) {
      this.contentInfo.station = i;
    },
    getContent2(i) {
      this.contentInfo.content = i;
    },
    getInfo() {
      if (this.contentInfo.station == "") {
        this.$message({
          message: "请输入岗位",
          type: "warning",
        });
        return;
      }
      if (this.contentInfo.content == "") {
        this.$message({
          message: "请输入工作内容",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "岗位",
          text: this.contentInfo.station,
        },
        {
          title: "工作内容",
          text: this.contentInfo.content,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "工作计划",
        url: "gzjh",
      });
      return Arry;
    },
  },
};
</script>
          <style>
.creation_main_title {
  font-size: 1.5rem;
  color: #799097;
  padding-bottom: 1rem;
  border-bottom: 1px solid #799097;
}
</style>
          