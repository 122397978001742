<template>
  <div id="userInfo">
    <div class="top">
      <div class="userInfo_head">
        <div class="head_img">
          <img src="../assets/logo.png" alt="" />
        </div>
        <div class="head_text">
          <div class="head_name">{{ userInfo.nickname }}</div>
          <div class="head_name1">
            <div class="head_vip" v-if="userInfo.vipType == 'common'">
              会员权益尚未开通
            </div>
            <div class="head_vip" v-if="userInfo.vipType == 'month'">
              月度会员
            </div>
            <div class="head_vip" v-if="userInfo.vipType == 'year'">
              年度会员
            </div>
            <div class="head_vip" v-if="userInfo.vipType == 'experience'">
              体验会员
            </div>
            <div class="head_vip" v-if="userInfo.vipType == 'perpetual'">
              永久会员
            </div>
            <div class="head_vip" v-if="userInfo.vipDate">
              /{{ userInfo.vipDate }}
            </div>
          </div>
        </div>
      </div>
      <el-popover placement="right" trigger="click" popper-class="el-set">
        <div>请到AI智能写作助手app端开通会员</div>
        <div
          class="vip_btn"
          v-if="userInfo.vipType == 'common'"
          slot="reference"
        >
          开通vip
        </div>
        <div
          class="vip_btn"
          v-if="
            userInfo.vipType == 'month' ||
            userInfo.vipType == 'year' ||
            userInfo.vipType == 'experience'
          "
          slot="reference"
        >
          续费
        </div>
      </el-popover>
    </div>
    <div class="bottom">
      <div class="bottom_title">解锁会员权益</div>
      <div class="bottom_box">
        <div class="box_item" v-for="(item, i) in list" :key="i">
          <img :src="item.img" alt="" />
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from "../assets/data.js";
export default {
  props: {
    userInfo: {
      type: Object,
      default: {},
    },
  },
  created() {
    // console.log(this.userInfo);
  },
  data() {
    return {
      list: data.data.vipEquity,
    };
  },
  methods: {
    openVip() {
      // this.$emit("openVip");
    },
  },
};
</script>


<style>
.top {
  width: 100%;
  /* height: 12rem; */
  background: url(../assets/image/userinfo_top.png) no-repeat;
  background-size: 100% 100%;
  padding-bottom: 1rem;
}
.userInfo_head {
  display: flex;
  padding: 1.2rem;
}
.head_img img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.head_text {
  margin-left: 0.8rem;
}
.head_name {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 1.2rem;
  color: #333333;
}
.head_name1 {
  display: flex;
}
.head_vip {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1rem;
  color: #d17300;
  margin-top: 0.5rem;
}
.vip_btn {
  width: 90%;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  background: linear-gradient(-25deg, #fffbf5, #ffdcb6);
  border-radius: 1.5rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 1.3rem;
  color: #c66500;
  margin: 0 auto;
  cursor: pointer;
}
.vip_text {
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
}
.bottom {
  width: 100%;
  padding: 1.5rem 0;
  background: #f9faff;
  border-radius: 1.5rem;
}
.bottom_title {
  width: 100%;
  height: 2.5rem;
  background: url(../assets/image/userinfo_vipbg.png) no-repeat;
  background-position: 0 5px;
  background-size: 100%;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1rem;
  color: #c86a0c;
}
.bottom_box {
  width: 98%;
  padding: 0 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.box_item {
  width: 6rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 0.9rem;
  color: #000000;
  margin-bottom: 0.8rem;
}
.box_item img {
  width: 80%;
  height: 80%;
  margin-bottom: 0.5rem;
}
</style>