import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import axios from 'axios'
 
// ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueScroller from 'vue-scroller'
Vue.use(ElementUI);
Vue.use(VueScroller)
Vue.prototype.axios = axios;
Vue.config.productionTip = false

// 引入rem
import  './utils/rem'

new Vue({
  el: '#app',
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
