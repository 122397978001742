<template>
  <div id="problem">
    <!-- 头像 -->
    <div class="ask_head">
      <img src="../assets/image/Aitalk.png" alt="" />
    </div>
    <div class="problem_bg">
      <!-- 头部提示语 -->
      <div class="problem_top">
        <div class="top_text">
          <div class="top_text1">你好！ 我是Ai对话</div>
          <div class="top_text2">
            请问有什么我可以帮助你的吗?无论是关于生活、工作、学习还是其他方面的问题，我都会尽力回答你。
          </div>
        </div>
      </div>
      <!-- 常见问题 -->
      <div class="problemlist">
        <!-- 图片定位 -->
        <img class="logo" src="../assets/image/problem.png" alt="" />

        <div class="problemlist_top">
          <div>你可以尝试这样问我：</div>
        </div>
        <div class="problemlist_box">
          <div class="p_box" v-for="(item, i) in aiProblem" :key="i" @click="question(item)">
            {{ item }}
            <img src="../assets/image/problem1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from "../assets/data.js";
export default {
  data() {
    return {
      aiProblem: data.data.aiProblem, //ai对话常见问题
    };
  },
  methods:{
    question(item){
      this.$emit("choiceQuestion",item);
    }
  }
};
</script>
<style>
#problem {
  display: flex;
  margin-bottom: 1rem;
}
.ask_head img {
  width: 4rem;
  margin-right: 1rem;
}
.problem_bg {
  width: 100%;
  background: #fff;
  border-radius: 1rem;
  padding: 1rem;
}
.problem_top {
  display: flex;
}
.top_text {
  width: 65%;
}
.top_text1 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
}
.top_text2 {
  font-size: 1.6rem;
}

.problemlist {
  position: relative;
}
.problemlist .logo {
  width: 15rem;
  position: absolute;
  top: -10rem;
  right: 0;
}
.problemlist_top {
  margin-top: 2rem;
  font-size: 1.6rem;
  font-weight: 500;
}
.problemlist_box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.2rem;
  justify-content: space-between;
}
.p_box {
  width: 46%;
  padding: 0 10px;
  height: 4rem;
  line-height: 4rem;
  border-radius: 1rem;
  background: #ecf4ff;
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}
.p_box:hover {
  background: #b1d1ff;
}
.p_box img {
  width: 2rem;
  height: 2rem;
}
</style>