<template>
  <div id="nav_bar">
    <div
      v-for="(item, i) in list"
      :key="i"
      :class="navIndex == i ? 'unav_box nav_box' : 'nav_box'"
      @click="change(i)"
    >
      {{ item }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: ["AI写作", "AI对话","AI顾问"],
      navIndex: 0,
    };
  },
  methods:{
    change(i){
        this.navIndex = i
        this.$emit('getnavIn',i)
    }
  }
};
</script>
<style>
#nav_bar {
  width: 128rem;
  height: 4rem;
  /* background: red; */
  display: flex;
}
.nav_box {
  height: 4rem;
  line-height: 4rem;
  font-size: 1.5rem;
  font-weight: 700;
  cursor: pointer;
  margin-right: 3rem;
}
.unav_box {
  color: #2873ff;
  border-bottom: 2px solid #2873ff;
}
.nav_box:hover {
  transform: scale(1.1);
}
</style>