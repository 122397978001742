<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>
    <creationAssembly1
      :content="content"
      @getType="getType"
      :disabled="disabled"
    ></creationAssembly1>
    <creationAssembly2
      :content="content1"
      @getContent="getContent1"
      :disabled="disabled"
    ></creationAssembly2>
    <creationAssembly2
      :content="content2"
      @getContent="getContent2"
      :disabled="disabled"
    ></creationAssembly2>
    <creationAssembly2
      :content="content3"
      @getContent="getContent3"
      inputType="number"
      :disabled="disabled"
    ></creationAssembly2>
  </div>
</template>
<script>
import creationAssembly1 from "@/components/creation_assembly1.vue";
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  components: {
    creationAssembly1,
    creationAssembly2,
  },
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "小红书",
      content: {
        title: "品类", //标题
        placeholder: "请输入自定义类型", // 文本框提示
        list: this.pageContent.variety, //标签列表
      },
      content1: {
        title: "产品名称",
        placeholder: this.pageContent.product,
      },
      content2: {
        title: "产品特点",
        placeholder: this.pageContent.trait,
      },
      content3: {
        title: "生成文本字数",
        placeholder: this.pageContent.text_lenth,
      },
      //   请求信息
      contentInfo: {
        variety: "",
        product: "",
        trait: "",
        length: "",
      },
      // disabled:false,
    };
  },
  created() {
    this.contentInfo.variety = this.pageContent.variety[0];
  },
  methods: {
    getType(i) {
      this.contentInfo.variety = i;
    },
    getContent1(i) {
      this.contentInfo.product = i;
    },
    getContent2(i) {
      this.contentInfo.trait = i;
    },
    getContent3(i) {
      this.contentInfo.length = i;
    },
    getInfo() {
      if (this.contentInfo.product == "") {
        this.$message({
          message: "请输入产品名称",
          type: "warning",
        });
        return;
      }
      if (this.contentInfo.trait == "") {
        this.$message({
          message: "请输入产品特点",
          type: "warning",
        });
        return;
      }
      if (this.contentInfo.length == "") {
        this.$message({
          message: "请输入文本字数",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "产品名称",
          text: this.contentInfo.product,
        },
        {
          title: "产品特点",
          text: this.contentInfo.trait,
        },
        {
          title: "文本字数",
          text: this.contentInfo.length,
        },
        {
          title: "品类",
          text: this.contentInfo.variety,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "小红书",
        url: "xhswa",
      });
      return Arry;
    },
  },
};
</script>
<style>
.creation_main_title {
  font-size: 1.5rem;
  color: #799097;
  padding-bottom: 1rem;
  border-bottom: 1px solid #799097;
}
</style>
