<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>
   
    <creationAssembly2
      :content="content1"
      @getContent="getContent1"
      :disabled="disabled"
    ></creationAssembly2>
    <creationAssembly2
      :content="content2"
      @getContent="getContent2"
      :disabled="disabled"
    ></creationAssembly2>
    <creationAssembly2
      :content="content3"
      @getContent="getContent3"
      inputType="number"
      :disabled="disabled"
    ></creationAssembly2>

    <creationAssembly1
      :content="content"
      @getType="getType"
      :disabled="disabled"
    ></creationAssembly1>
  </div>
</template>
  <script>
import creationAssembly1 from "@/components/creation_assembly1.vue";
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    creationAssembly1,
    creationAssembly2,
  },
  data() {
    return {
      title: "广告文案",
      content: {
        title: "选择语气", //标题
        placeholder: "请输入自定义类型", // 文本框提示
        list: this.pageContent.style, //标签列表
      },
      content1: {
        title: "广告主题",
        placeholder: this.pageContent.subject,
      },
      content2: {
        title: "围绕关键词",
        placeholder: this.pageContent.keyword,
      },
      content3: {
        title: "生成文本字数",
        placeholder: this.pageContent.text_lenth,
      },
      //   请求信息
      contentInfo: {
        style: "",
        subject: "",
        keyword: "",
        length: "",
      },
    };
  },
  created() {
    this.contentInfo.style = this.pageContent.style[0];
  },
  methods: {
    getType(i) {
      this.contentInfo.style = i;
    },
    getContent1(i) {
      this.contentInfo.subject = i;
    },
    getContent2(i) {
      this.contentInfo.keyword = i;
    },
    getContent3(i) {
      this.contentInfo.length = i;
    },
    getInfo() {
      if (this.contentInfo.subject == "") {
        this.$message({
          message: "请输入广告主题",
          type: "warning",
        });
        return;
      }
      if (this.contentInfo.keyword == "") {
        this.$message({
          message: "请输入关键词",
          type: "warning",
        });
        return;
      }
      if (this.contentInfo.length == "") {
        this.$message({
          message: "请输入文本字数",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "广告主题",
          text: this.contentInfo.subject,
        },
        {
          title: "关键词",
          text: this.contentInfo.keyword,
        },
        {
          title: "文本字数",
          text: this.contentInfo.length,
        },
        {
          title: "语气",
          text: this.contentInfo.style,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "广告文案",
        url: "ggwa",
      });
      return Arry;
    },
  },
};
</script>
  <style>

</style>
  