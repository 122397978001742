<template>
  <div id="adviser">
    <div v-if="talk" class="adviser_title">AI顾问</div>
    <div v-else class="adviser_title" style="color: #21a7f4;" @click="talk = true">
      <i class="el-icon-arrow-left"></i>
     {{title}}
    </div>
    <div class="adviser_lable" v-if="talk">
      <div
        class="adviser_box"
        v-for="(item,i) in adviserList"
        :key="i"
        @click="adviser(item)"
      >
        <div class="adviser_img">
          <img :src="item.icon" alt="" />
          {{ item.name }}
        </div>
        <div class="adviser_text">
         {{ item.subTitle }}
        </div>
      </div>
    </div>

    <div v-else>
      <adviserTalk :adviser="adviseritem" @openLogin="openLogin" @changeTitle="changeTitle"></adviserTalk>
    </div>
  </div>
</template>

<script>
import adviserTalk from "@/components/adviser_talk.vue";
import api from "@/api/api";
export default {
  components:{
    adviserTalk
  },
  data() {
    return {
      talk:true, //对话切换
      title:'', 
      adviserList:[]
    };
  },
  created(){
    this.getAdviser()
  },
  methods: {
    adviser(i) {
      // console.log(i);
      this.talk = false
      this.title = i.name
      this.adviseritem = i
    },
    changeTitle(title){
      this.title = title
    },
    openLogin(){
      this.$emit("openLogin");
    },
    // 获取顾问列表
    getAdviser(){
      api.getAdviser().then((res) =>{
        // console.log(res)
        if(res.code == 200){
          this.adviserList = res.data
        }
      })
    }
  },
};
</script>

<style>
#adviser {
  width: 123rem;
  margin-top: 2rem;
  padding: 2rem;
  overflow: auto;
  height: 78%;
  border-radius: 1rem;
}
.adviser_title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  cursor: pointer;
}
.adviser_lable {
  width: 114rem;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.9rem;
}
.adviser_box {
  width: 19rem;
  height: 7rem;
  padding: 1rem;
  margin-right: 1.8rem;
  background: #ffffff;
  margin-bottom: 2rem;
  border-radius: 1rem;
}
.adviser_img {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
}
.adviser_img img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
}
.adviser_text {
  font-size: 1rem;
  margin-top: 0.5rem;
  width: 100%;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
}
</style>