<template>
    <div>
      <div class="creation_main_title">{{ title }}</div>
      
      <creationAssembly2
        :content="content1"
        @getContent="getContent1"
        :disabled="disabled"
      ></creationAssembly2>
      <creationAssembly2
        :content="content2"
        @getContent="getContent2"
        inputType="number"
        :disabled="disabled"
      ></creationAssembly2>

      <creationAssembly1
        :content="content"
        @getType="getType"
        :disabled="disabled"
      ></creationAssembly1>
    </div>
  </template>
    <script>
  import creationAssembly1 from "@/components/creation_assembly1.vue";
  import creationAssembly2 from "@/components/creation_assembly2.vue";
  export default {
    props: {
      pageContent: {
        type: Object,
        default: {},
      },
    disabled: {
      type: Boolean,
      default: false,
    },
    },
    components: {
      creationAssembly1,
      creationAssembly2,
    },
    data() {
      return {
        title: "视频脚本",
        content: {
          title: "风格", //标题
          placeholder: "请输入自定义类型", // 文本框提示
          list: this.pageContent.style, //标签列表
        },
        content1: {
          title: "短视频主题",
          placeholder: this.pageContent.subject,
        },
        content2: {
          title: "时长",
          placeholder: this.pageContent.text_lenth,
        },
        //   请求信息
        contentInfo: {
          style: "",
          subject: "",
          duration: "",
        },
      };
    },
    created() {
      this.contentInfo.style = this.pageContent.style[0];
    },
    methods: {
      getType(i) {
        this.contentInfo.style = i;
      },
      getContent1(i) {
        this.contentInfo.subject = i;
      },
      getContent2(i) {
        this.contentInfo.duration = i;
      },
      getInfo() {
        if (this.contentInfo.subject == "") {
          this.$message({
            message: "请输入短视频主题",
            type: "warning",
          });
          return;
        }
        if (this.contentInfo.duration == "") {
          this.$message({
            message: "请输入时长",
            type: "warning",
          });
          return;
        }
        let Arry = [];
        let detailInfo = [
          {
            title: "主题",
            text: this.contentInfo.subject,
          },
          {
            title: "时长",
            text: this.contentInfo.duration,
          },
          {
            title: "风格",
            text: this.contentInfo.style,
          },
        ];
        Arry.push(this.contentInfo);
        Arry.push(detailInfo);
        Arry.push({
          text: "视频脚本",
          url: "spjb",
        });
        return Arry;
      },
    },
  };
  </script>
    <style>
  .creation_main_title {
    font-size: 1.5rem;
    color: #799097;
    padding-bottom: 1rem;
    border-bottom: 1px solid #799097;
  }
  </style>
    