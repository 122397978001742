<template>
  <div class="noData">
    <img :style="{width:width}" src="../assets/image/no_data.png" alt="" />
    {{text}}
  </div>
</template>
<script>
export default {
  props:{
    width:{
      type: String,
      default: '80%',
    },
    text:{
      type: String,
      default: '暂无数据',
    }
  }
}

</script>
<style>
.noData {
  display: flex;
  flex-flow: column;
  align-items: center;
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 1.5rem;
  color: #333333;
}
/* .noData img{
    width: 80%;
} */
</style>