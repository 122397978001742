<template>
  <div>
    <el-dialog
      :visible.sync="vipShow"
      width="750px"
      :show-close="false"
      @close="colseLogin"
    >
      <div class="vip_bg">
        <!-- 头像 -->
        <div class="head">
          <div class="head_img">
            <img class="head_img1" src="../assets/logo.png" alt="" />
            <img
              v-if="userInfo.vip"
              class="head_img2"
              src="../assets/image/vip_state1.png"
              alt=""
            />
            <img
              v-else
              class="head_img2"
              src="../assets/image/vip_state.png"
              alt=""
            />
          </div>
          <div class="user">
            <div class="user_name">{{ userInfo.nickname }}</div>
            <div class="user_name1">
              <div class="user_time" v-if="userInfo.vipType == 'common'">
                会员权益尚未开通
              </div>
              <div class="user_time" v-if="userInfo.vipType == 'month'">
                月度会员
              </div>
              <div class="user_time" v-if="userInfo.vipType == 'year'">
                年度会员
              </div>
              <div class="user_time" v-if="userInfo.vipType == 'experience'">
                体验会员
              </div>
              <div class="user_time" v-if="userInfo.vipType == 'perpetual'">
                永久会员
              </div>
              <div class="user_time">
                /{{ userInfo.vipDate ? userInfo.vipDate : "" }}
              </div>
            </div>
          </div>
        </div>

        <!-- vip价格 -->
        <div class="vip_list">
          <div class="vip_title">vip套餐选择</div>

          <div class="price_list">
            <div
              :class="vipIndex == i ? 'price_box uprice_box' : 'price_box'"
              v-for="(item, i) in vipList"
              :key="i"
              @click="ckickVip(item, i)"
            >
              <div v-if="i == 2" class="price_lable">限时特惠</div>
              <div class="price">
                <span class="price1">￥</span> {{ item.price }}
              </div>
              <div class="character" v-if="item.name">{{ item.name }}</div>
              <div class="character" v-if="item.typeName">
                {{ item.typeName }}
              </div>
              <div class="oprice" v-if="item.description">
                {{ item.description }}
              </div>
              <div class="vipOprice">￥{{ item.originalCost }}</div>
            </div>
          </div>

          <div class="preferential">
            <div>限时专项特惠</div>
            <div>-￥{{ preferential }}</div>
          </div>

          <div class="pay">
            <div class="pay_ewm"></div>
            <div class="pay_way">
              <div class="pay_price">
                <div class="pay_price1">
                  <span>￥</span> {{ payPrice }}
                </div>
                <div class="pay_price2">限时特惠</div>
              </div>
              <div class="pay_way1 pay_way2">
                <img src="../assets/image/zfb.png" alt="" />
                <img src="../assets/image/wx.png" alt="" />
                支付宝、微信扫码支付
              </div>
              <div class="pay_way1">我已阅读同意《会员服务协议》</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
export default {
  props: {
    vipShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInfo: {},
      vipPop: this.vipShow, //弹框显示关闭
      vipList: [], //会员价格
      vipIndex: 2, //会员选择下标
      preferential: 232, //优惠价格
      payPrice: 169, //支付价格
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getVipprice();
  },
  methods: {
    // 关闭vip弹框
    colseLogin() {
      this.$emit("closeVip");
    },
    // 事件传值
    getUserinfo(data) {
      this.userInfo = data;
    },
    // 获取vip价格
    getVipprice() {
      api.experienceVipPrice().then((res) => {
        // console.log("体验vip", res.data);
        this.vipList = this.vipList.concat(res.data);
      });
      api.vipPrice().then((res) => {
        // console.log("vip", res.data);
        this.vipList = this.vipList.concat(res.data.reverse());
      });
    },
    ckickVip(item, i) {
      this.vipIndex = i;
      this.preferential = item.originalCost - item.price;
      this.payPrice = item.price;
    },
  },
};
</script>
<style>
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
.vip_bg {
  height: 500px;
  background: url(../assets/image/vip_bg.png);
  background-size: 100% 100%;
}
.head {
  display: flex;
  padding: 20px;
}
.head_img {
  position: relative;
}
.head_img1 {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.head_img .head_img2 {
  position: absolute;
  bottom: -5px;
  left: -2px;
  width: 40px;
  height: 22px;
}
.user {
  margin-left: 10px;
}
.user_name {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 14px;
  color: #8d4b0c;
  margin: 5px 0;
}
.user_name1{
  display: flex;
}
.user_time {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 11px;
  color: #8d4b0c;
}
.vip_list {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45rem;
  height: 31rem;
  background: linear-gradient(-58deg, #f9d9be 0%, #faf9fa 100%);
  border-radius: 11px;
  padding: 20px;
}
.vip_title {
  width: 100%;
  background: url(../assets/image/vip1.png) no-repeat;
  background-size: 19px 14px;
  padding-left: 25px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 10px;
  color: #8d4b0c;
}
.price_list {
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.price_box {
  width: 80px;
  height: 80px;
  background: #fff5ea;
  border-radius: 11px;
  border: 1px solid #f0cd99;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; /* 不缩小 */
  box-sizing: border-box; /* 包含内边距和边框 */
  position: relative;
}
.uprice_box {
  background: linear-gradient(-38deg, #fbdeb3, #fefaee);
}
.price_lable {
  position: absolute;
  top: -12px;
  right: -20%;
  height: 20px;
  line-height: 20px;
  background: #ff4444;
  padding: 1px 8px;
  box-shadow: 0px 3px 6px 0px rgba(69, 10, 10, 0.2);
  border-radius: 12px 11px 11px 0px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
}
.price {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 23px;
  color: #a43711;
  cursor: pointer;
}
.price .price1 {
  font-size: 10px;
}
.character {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 8px;
  color: #a43811;
  margin: 5px 0;
  cursor: pointer;
}
.oprice {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 8px;
  color: #c56542;
  background: url(../assets/image/vip2.png) no-repeat;
  background-size: 12px 8px;
  padding-left: 15px;
  background-position: left 2px;
  cursor: pointer;
}
.vipOprice {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #c56542;
  text-decoration-line: line-through;
}
.preferential {
  width: 37rem;
  height: 30px;
  line-height: 25px;
  text-align: center;
  background: #fff5ea;
  border-radius: 11px;
  border: 1px solid #ef4b4b;
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1.2rem;
  color: #a43711;
  line-height: 32px;
  background: url(../assets/image/vip3.png) no-repeat;
  background-size: 21px 18px;
  background-position: 10px 6px;
  cursor: pointer;
}
.pay {
  width: 41rem;
  padding: 1rem;
  background: #ffffff;
  border-radius: 11px;
  display: flex;
  margin-top: 20px;
  cursor: pointer;
}
.pay_ewm {
  width: 80px;
  height: 80px;
  background: #000;
  margin-right: 10px;
}
.pay_price {
  display: flex;
}
.pay_price1 {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 23px;
  color: #a43711;
  line-height: 50px;
}
.pay_price1 span{
  font-size: 10px
}
.pay_price2 {
  width: 48px;
  height: 17px;
  line-height: 17px;
  text-align: center;
  background: #ff4444;
  box-shadow: 0px 2px 5px 0px rgba(69, 10, 10, 0.2);
  border-radius: 9px 8px 8px 0px;
  color: #ffffff;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 8px;
  color: #ffffff;
  margin-top: 5px;
}
.pay_way1 {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 8px;
  color: #7b7b96;
}
.pay_way2 img:first-child {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.pay_way2 img:last-child {
  width: 11px;
  height: 9px;
  margin-right: 5px;
}
</style>