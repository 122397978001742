<template>
  <div>
    <div class="writing_vip">
      <div class="writing_title">{{ title }}</div>
      <div class="lable">
        <div
          class="lable_box lable_vip"
          v-for="(item, i) in list"
          :key="i"
          @click="change(item)"
        >
          <div v-if="vip" class="lable_vip1">会员</div>
          <div class="lable_img">
            <img :style="{width:width}" :src="item.icon" alt="" />
          </div>
          <div class="lable_text">
            <div class="lable_name">{{ item.name }}</div>
            <div class="lable_description">{{item.description}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: "",
    },
    vip: {
      type: Boolean,
      default: false,
    },
    width:{
      type: String,
      default: '6rem',
    }
  },
  methods: {
    change(item) {
      let data = {
        title: this.title,
        item: item,
      };
      this.$emit("change", data);
    },
  },
};
</script>
<style>
.writing_title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.lable {
  width: 110rem;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.lable_box {
  width: 18rem;
  padding: 1rem;
  background: #ffffff;
  border-radius: 1rem;
  height: 6rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}
.lable_img img {
  /* width: 6rem; */
  /* height: 5.3rem; */
  margin-right: 1rem;
}

.lable_text {
  font-size: 1rem;
  margin-top: 1rem;
}
.lable_name{
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.lable_description{
  width: 11rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 显示的行数，可以根据需要修改 */
  overflow: hidden;
  text-overflow: ellipsis;
}
.lable_vip {
  position: relative;
}
.lable_vip1 {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  background: linear-gradient(to right, #f8d9ad, #e7b270);
  border-radius: 2rem 1.5rem 0 2rem;
  color: #512d00;
}
</style>