
let data = {
  homeList: [{
    img: 'http://www.hbsry.com/i/1.png',
    text1: '政企事业单位网站开发、H5动画制作',
    text2: '专注于互联网信息化高新技术产品研发和创新应用'
  },
  {
    img: 'http://www.hbsry.com/i/2.png',
    text1: '手机APP、小程序、系统软件开发',
    text2: '专注于互联网信息化高新技术产品研发和创新应用'
  },
  {
    img: 'http://www.hbsry.com/i/3.png',
    text1: 'VIS设计、画册、杂志设计',
    text2: '专注于互联网信息化高新技术产品研发和创新应用'
  },
  {
    img: 'http://www.hbsry.com/i/4.png',
    text1: '回报宣传PPT制作',
    text2: '专注于互联网信息化高新技术产品研发和创新应用'
  }],
  productList: ['http://www.hbsry.com/img/product.jpg'],

  // ai对话
  Aibtn: [
    {
      img: require('@/assets/image/Aitalk1.png'),
      text: 'Ai对话'
    }, {
      img: require('@/assets/image/vip.png'),
      text: 'vip'
    }, {
      img: require('@/assets/image/New.png'),
      text: '新建'
    }, {
      img: require('@/assets/image/download.png'),
      text: '下载'
    }
  ],

  //vip权益
  vipEquity: [{
    img: require('@/assets/image/userInfo1.png'),
    text: '解锁全部功能'
  },
  {
    img: require('@/assets/image/userInfo2.png'),
    text: '不限次数'
  }, {
    img: require('@/assets/image/userInfo3.png'),
    text: '玩转Ai写作'
  }, {
    img: require('@/assets/image/userInfo4.png'),
    text: '客服专属'
  }, {
    img: require('@/assets/image/userInfo5.png'),
    text: '超低延迟'
  }, {
    img: require('@/assets/image/userInfo6.png'),
    text: '永久保存'
  }, {
    img: require('@/assets/image/userInfo7.png'),
    text: '支持续写功能'
  }, {
    img: require('@/assets/image/userInfo8.png'),
    text: '更优质结果'
  }],

  // Ai对话常见问题
  aiProblem: ['写一篇防溺水安全教育报告', '写一个社区活动方案', '写一篇童话故事，话题是励志', '写一篇高中议论文关于勇气', '外星人真的存在吗'],

  set: [{
    img: require('@/assets/image/set2.png'),
    text: '用户协议'
  }, {
    img: require('@/assets/image/set3.png'),
    text: '隐私政策'
  }, {
    img: require('@/assets/image/set4.png'),
    text: '退出登录'
  }],

  homeHot: [
    {
      title:'热门创作',
      icon: require('@/assets/image/lable.png'),
      name: 'Ai写文章',
      description: '一键智能文章写作',
      type: 'xwz',
      id:100,
    },
    {
      title:'热门创作',
      icon: require('@/assets/image/lable2.png'),
      name: '写新闻稿',
      description: '智能撰写新闻稿',
      type: 'xxwg',
      id:101,
    },
    {
      title:'热门创作',
      icon: require('@/assets/image/lable1.png'),
      name: '原创改写',
      description: '一键伪原创',
      type: 'ycgx',
      id:102,
    }
  ],

  ewm: 'Ci5pbXBvd2VyQm94IC50aXRsZSB7CiAgICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5xcmNvZGUubGlnaHRCb3JkZXIgewogICAgd2lkdGg6IDYuM3JlbTsKIHBvc2l0aW9uOiBhYnNvbHV0ZTsKICB0b3A6IC0xLjlyZW07CiAgbGVmdDogMDsKfQouaW1wb3dlckJveCAuaW5mbyB7CiAgICBkaXNwbGF5OiBub25lOwp9'

}

module.exports = {
  data: data
}