<template>
  <div id="creation">
    <div v-if='!found' class="creation_main">
      <div class="expect">
        <img src="../assets/image/expect.png" alt="">
      </div>
    </div>
    <div v-else class="creation_main">
      <xhs v-if="pageType == 'xhswa' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></xhs>
      <ggwa v-if="pageType == 'ggwa' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></ggwa>
      <xxs v-if="pageType == 'xxs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></xxs>
      <xzw v-if="pageType == 'xzw' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></xzw>
      <gzh v-if="pageType == 'gzh' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></gzh>
      <zhhd v-if="pageType == 'zhwt' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></zhhd>
      <wzrs v-if="pageType == 'wzrs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></wzrs>
      <spjb v-if="pageType == 'spjb' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></spjb>
      <dypl v-if="pageType == 'dypl' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></dypl>
      <gjctq v-if="pageType == 'gjctq' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></gjctq>
      <lxbg v-if="pageType == 'lxbg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></lxbg>
      <yjg v-if="pageType == 'yjg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></yjg>
      <znfy v-if="pageType == 'znfy' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></znfy>
      <wbzy v-if="pageType == 'wbzy' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></wbzy>
      <xxwg v-if="pageType == 'xxwg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></xxwg>
      <xwz v-if="pageType == 'xwz' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></xwz>
      <ycgx v-if="pageType == 'ycgx' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></ycgx>

      <!-- vip -->
      <wgxx v-if="pageType == 'wgxx' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></wgxx>
      <xmtxz v-if="pageType == 'xmtxz' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></xmtxz>
      <jasj v-if="pageType == 'jasj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></jasj>
      <ggc v-if="pageType == 'ggc' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></ggc>
      <zngx v-if="pageType == 'zngx' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></zngx>

      <!-- 办公 -->
      <gwxz v-if="pageType == 'gwxz' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></gwxz>
      <gzjh v-if="pageType == 'gzjh' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></gzjh>
      <hychfa v-if="pageType == 'hychfa' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
      </hychfa>
      <gzhb v-if="pageType == 'gzhb' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></gzhb>
      <pptdg v-if="pageType == 'pptdg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></pptdg>
      <htmb v-if="pageType == 'htmb' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></htmb>
      <zbs v-if="pageType == 'zbs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></zbs>
      <khzj v-if="pageType == 'khzj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></khzj>
      <zgbg v-if="pageType == 'zgbg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></zgbg>
      <zczj v-if="pageType == 'zczj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></zczj>
      <hyjy v-if="pageType == 'hyjy' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></hyjy>
      <yjya v-if="pageType == 'yjya' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></yjya>
      <xdth v-if="pageType == 'xdth' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></xdth>
      <gzzj v-if="pageType == 'gzzj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></gzzj>
      <fxbg v-if="pageType == 'fxbg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></fxbg>
      <hdfa v-if="pageType == 'hdfa' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></hdfa>
      <pxzj v-if="pageType == 'pxzj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></pxzj>
      <jhxd v-if="pageType == 'jhxd' && pageShow" ref="creation" :pageContent="content" :disabled="disabled"></jhxd>
    </div>
    <div class="creation_bottom">
      <div class="creation_btn" @click="creation">{{ btnText }}</div>
      <!-- <div class="creation_text">剩余创作次数：0 次</div> -->
      <div v-if="userInfo.vipType == 'experience'" class="creation_text">
        剩余字符{{ userInfo.surplusTokens }}
      </div>
      <div v-if="userInfo.vipType == 'common'" class="creation_text">
        免费次数{{ freeNum }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import xhs from "@/components/home/xhs.vue";
import ggwa from "@/components/home/ggwa.vue";
import xxs from "@/components/home/xxs.vue";
import xzw from "@/components/home/xzw.vue";
import gzh from "@/components/home/gzh.vue";
import zhhd from "@/components/home/zhhd.vue";
import wzrs from "@/components/home/wzrs.vue";
import spjb from "@/components/home/spjb.vue";
import dypl from "@/components/home/dypl.vue";
import gjctq from "@/components/home/gjctq.vue";
import lxbg from "@/components/home/lxbg.vue";
import yjg from "@/components/home/yjg.vue";
import znfy from "@/components/home/znfy.vue";
import wbzy from "@/components/home/wbzy.vue";
import xxwg from "@/components/home/xxwg.vue";
import xwz from "@/components/home/xwz.vue";
import ycgx from "@/components/home/ycgx.vue";

import wgxx from "@/components/vip/wgxx.vue";
import xmtxz from "@/components/vip/xmtxz.vue";
import jasj from "@/components/vip/jasj.vue";
import ggc from "@/components/vip/ggc.vue";
import zngx from "@/components/vip/zngx.vue";

import gwxz from "@/components/work/gwxz.vue";
import gzjh from "@/components/work/gzjh.vue";
import hychfa from "@/components/work/hychfa.vue";
import gzhb from "@/components/work/gzhb.vue";
import pptdg from "@/components/work/pptdg.vue";
import htmb from "@/components/work/htmb.vue";
import zbs from "@/components/work/zbs.vue";
import khzj from "@/components/work/khzj.vue";
import zgbg from "@/components/work/zgbg.vue";
import zczj from "@/components/work/zczj.vue";
import hyjy from "@/components/work/hyjy.vue";
import yjya from "@/components/work/yjya.vue";
import xdth from "@/components/work/xdth.vue";
import gzzj from "@/components/work/gzzj.vue";
import fxbg from "@/components/work/fxbg.vue";
import hdfa from "@/components/work/hdfa.vue";
import pxzj from "@/components/work/pxzj.vue";
import jhxd from "@/components/work/jhxd.vue";

import { pagelist } from '@/utils/pagelist.js'
export default {
  props: {
    initialType: {
      type: String,
      default: "",
    },
  },
  components: {
    xhs,
    ggwa,
    xxs,
    xzw,
    gzh,
    zhhd,
    wzrs,
    spjb,
    dypl,
    gjctq,
    lxbg,
    yjg,
    znfy,
    wbzy,
    wgxx,
    xmtxz,
    jasj,
    gwxz,
    gzjh,
    hychfa,
    gzhb,
    pptdg,
    htmb,
    zbs,
    khzj,
    zgbg,
    zczj,
    hyjy,
    yjya,
    xdth,
    gzzj,
    fxbg,
    hdfa,
    pxzj,
    jhxd,
    ggc,
    zngx,
    xxwg,
    xwz,
    ycgx
  },
  data() {
    return {
      pageType: this.initialType, //显示组件
      content: {}, //组件提示信息
      pageShow: false,
      freeNum: 0, // 免费次数
      userInfo: {},
      btnText: "立即创作", //btn提示文字
      disabled: false, //是否禁用
      found: false,  //是否存在
      typeList: pagelist,  //所有存在页面
    };
  },
  created() {
    this.existencepage(this.pageType)
    this.initialization()

  },
  methods: {
    //判断是否存在
    existencepage(type) {
      for (const element of this.typeList) {
        if (element == type) {
          this.found = true;
          break;
        }
      }
    },
    // 初始化
    initialization(){
      if (this.found) {
      this.getPrompt(this.pageType);
      this.getFreeNum();
      if (JSON.parse(localStorage.getItem("userInfo"))) {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      }
      this.$root.$on('user-refreshed', () => {
        this.getFreeNum()
        if (JSON.parse(localStorage.getItem("userInfo"))) {
          this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        }
      })
    }
    },
    // 获取免费次数
    getFreeNum() {
      api.degree().then((res) => {
        // console.log(res);
        this.freeNum = res.data;
      });
    },
    // 通过左边切换组件
    changeType(i) {
      // console.log(i)
      this.disabled = false;
      this.btnText = "立即创作";
      this.pageType = i;
      this.pageShow = false;
      this.found = false
      this.existencepage(i)
      if(this.found){
        this.getPrompt(i);
      }
      
    },
    // 获取提示信息
    getPrompt(i) {
      api.getPrompt(i).then((res) => {
        console.log(this.pageType, res)
        if (res.code == 200) {
          res.data.text_lenth = '请输入字数(<=' + res.data.text_lenth + ')'
          this.content = res.data;
          this.pageShow = true;
        }
      });
    },
    // 创作
    creation() {
      // console.log(this.$refs.creation.getInfo())
      if(!this.found){
        this.$message({
          message: "敬请期待",
          type: "warning",
        });
        return
      }
      if (this.btnText == '创作中') {
        console.log('创作中')
        return
      }
      if (this.btnText == '继续创作') {
        this.btnText = "立即创作";
        this.disabled = false;
        return
      }
      if (this.$refs.creation.getInfo()) {
        this.btnText = "创作中";
        this.disabled = true;
        this.$emit("getInfo", this.$refs.creation.getInfo());
      }
    },
    disallow() {
      this.btnText = "继续创作";
    },
  },
};
</script>
<style>
#creation {
  width: 30rem;
  height: 100%;
  background: #ffffff;
  position: relative;
}
.expect{
  width: 22rem;
  margin: 0 auto;
  margin-top: 10rem;
}
.expect img{
 width:100% ;
}


.creation_main {
  height: 72vh;
  overflow: auto;
  padding: 0 1rem;
  margin-top: 2%;
}

.creation_bottom {
  position: absolute;
  bottom: 0;
  height: 15vh;
  width: 100%;
  background: #ffffff;
}

.creation_btn {
  width: 60%;
  text-align: center;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.2rem;
  color: #ffffff;
  background: #409eff;
  margin: 0 auto;
  border-radius: 3rem;
  margin-top: 5%;
  cursor: pointer;
}

.creation_text {
  text-align: center;
  margin-top: 2%;
  font-size: 1rem;
}

.creation_main .creation_main_title {
  font-size: 1.8rem;
  color: #333;
  padding-bottom: 1rem;
  border-bottom: 1px solid #799097;
}
</style>