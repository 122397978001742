<template>
  <div>
    <div class="talk_text" v-html="text">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text: "", //显示的最终文本
      contentStr: "",
      // 定时器ID
      interval: null,
      end: false
    };
  },
  created() {
    
  },
  watch: {
    content(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.contentStr = newVal
      }
    },
  },
  mounted() {
    // 开启文字逐个显示定时器
    this.animateText();
  },
  onHide() {
    // 组件注销  定时器清除
    clearInterval(this.interval);
  },
  methods: {
    anwserOver() {
      this.end = true
    },
    rewriting(){
      this.text = ''
      this.contentStr= ""
      this.end = false
      this.animateText();
    },
    animateText() {
      this.contentStr = this.content;
      // 用来储存当前读取到第几个字符了
      let counter = 0;
      this.text = "";
      // 使用setInterval来逐字显示文本
      this.interval = setInterval(() => {
        if (counter < this.contentStr.length) {
          if (this.contentStr !== "") {
            if (counter < this.contentStr.length) {
              // 获取当前需要展示的文字
              const showText = this.contentStr[counter];
              // 凭借字符串
              this.text += showText;
              counter += 1;
            }
            if(counter == this.contentStr.length && this.end){
              // console.log('1111111----结束')
              this.$emit("anwserOver");
              clearInterval(this.interval);
            }
            // if (counter == this.contentStr.length) {
            //   // console.log('结束')
            //   this.$emit("anwserOver");
            //   clearInterval(this.interval);
            // }
          }
        }
      }, 50); // 每50毫秒添加一个字符
    },
  },
};
</script>

<style>
</style>