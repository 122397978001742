<template>
  <div id="history">
    <div class="title">
      <img src="../assets/image/history.png" alt="" />
      历史记录
    </div>

    <div class="history_list">
      <div class="history_nodata" v-if="list.length == 0">
        <no-data></no-data>
      </div>
      <div v-else>
        <ul
          ref="histroyList"
          v-infinite-scroll="loadMore"
          style="overflow: auto; height: 82vh"
        >
          <div
            :class="histroyIndex == i ? 'list_box list_ubox' : 'list_box'"
            v-for="(item, i) in list"
            :key="i"
            @click="history(i, item)"
          >
            <img class="adviserIcon" v-if="item.adviserIcon" :src="item.adviserIcon"/>
            <img class="adviserIcon" v-else src="../assets/image/Aitalk.png"/>
            <div class="text">{{ item.title }}</div>
            <div class="history_img" v-if="histroyIndex == i">
              <img
                @click="del(item.id)"
                src="../assets/image/history2.png"
                alt=""
              />
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
import noData from "@/components/noData.vue";
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    noData,
  },
  data() {
    return {
    list: [], // 历史记录
      histroyIndex: -1, //选中历史下标
      isLoading: false,
    };
  },
  created() {
    if (this.type == "talk") {
      this.getHistory();
    }
    if (this.type == "adviser") {
      this.getadviserHistory();
    }
    // console.log('1111',this.type)
  },
  methods: {
    // 选择对话
    history(i, item) {
      this.histroyIndex = i;
      // console.log('详情',item)
      let title = "";
      if (item.adviserName) {
        title = item.adviserName;
      }
      this.$emit("detailId", {
        id: item.id,
        history: true,
        title: title,
        adviserIcon:item.adviserIcon
      });
    },
    // 删除对话
    del(id) {
      this.$confirm("是否删除此聊天信息", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .deleteSession({
              ids: id,
            })
            .then((res) => {
              if (res.code == 200) {
                this.list = this.list.filter((item, i) => {
                  if (id !== item.id) {
                    return item;
                  }
                });
                this.histroyIndex = -1;
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.$emit("newTalk");
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取历史对话
    getHistory() {
      api
        .sessionList({
          pageNum: 1,
          pageSize: 20,
        })
        .then((res) => {
          this.list = res.data;
          // console.log("histroyList", this.list);
        });
    },
    getadviserHistory() {
      api
        .getAdviserlist({
          pageNum: 1,
          pageSize: 20,
        })
        .then((res) => {
          this.list = res.data;
          // console.log("histroyList", this.list);
        });
    },
    loadMore() {
      // console.log("加载111");
    },
  },
};
</script>
  <style >
::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
  display: none;
}

#history {
  width: 13rem;
  /* height: 92%; */
  margin-top: 4vh;
  border-left: 1px solid #ccc9d9;
}
.history_nodata {
  padding-top: 10rem;
}
#history .title {
  width: 7rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 2.5rem;
  margin: 0 auto;
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 1rem;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 0 2rem;
  cursor: pointer;
}
.title img {
  width: 1.6rem;
  height: 1.6rem;
}
.history_list {
  width: 12rem;
  margin-left: 0.8rem;
  /* background: red; */
}
.history_list ul {
  padding: 0;
}
.history_list .list_box {
  width: 12rem;
  padding: 0 0.5rem;
  height: 3rem;
  line-height: 3rem;
  display: flex;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ccc9d9;
}
.history_list  .list_ubox {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 2rem;
  border:none;
}
.list_box .text {
  width: 7rem;
  color: #484972;
  overflow: hidden;
  cursor: pointer;
}
.history_img img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}
.adviserIcon {
  width: 3rem;
  height: 3rem;
  /* background: red; */
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
}
</style>