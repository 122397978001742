<template>
  <div class="login2">
    <el-dialog
      :visible.sync="visible"
      :show-close="false"
      @close="colseLogin"
      class="custom-dialog-width"
      width="60%"
    >
      <div id="login">
        <div id="login1">
          <!-- 二维码 -->
          <div class="ewm">
            <div class="ewm_title">微信扫码登录</div>
            <div class="ewm_box">
              <!-- -->
              <div class="ewm_box1">
                <div ref="vxqr" id="vxqr"></div>
                <!-- <wxlogin
                  appid="wxc8cb7d946e617297"
                  scope="snsapi_login"
                  redirect_uri="http://localhost"
                  :href="href"
                ></wxlogin> -->
              </div>
            </div>
          </div>
          <!-- 账号登录 -->
          <div class="phone">
            <div class="phone_title">Hello,欢迎登录</div>
            <div class="phone_line"></div>
            <div class="login_phone">
              <div class="login_phoneText">+86</div>
              <input
                type="text"
                placeholder="请输入手机号"
                v-model="phone"
                maxlength="11"
              />
            </div>
            <div class="login_password">
              <div class="password">
                <div>
                  <img src="../assets/image/login_code.png" alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    v-model="code"
                    placeholder="请输入验证码"
                  />
                </div>
              </div>
              <div id="get-code" class="time" v-if="!codeTime">
                发送验证码
              </div>
              <div class="time" v-else>{{ time }} s</div>
            </div>
            <div id="captcha-element"></div>
            <div class="login_btn" @click="login">登录</div>
          </div>

          <div @click="colseLogin" class="colse">
            <img src="../assets/image/login_close.png" alt="" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import WxLogin from "vue-wxlogin";
import data from "@/assets/data";
import api from "@/api/api";
import { Base64 } from "js-base64";
export default {
  props: {
    loginShow: {
      type: Boolean,
      default: false,
    },
  },
  // components: {
  //   wxlogin,
  // },
  data() {
    return {
      // login: this.loginShow, //弹框显示关闭
      href: "data:text/css;base64," + data.data.ewm,
      codeTime: false, //是否倒计时
      time: 60, //倒计时
      phone: "", //电话15171998002
      code: "", //验证码1987
      visible: false,
      css: "#tpl_for_iframe{width: {width}px;}.impowerBox .qrcode {width: {width}px; margin-top: 0px;}.impowerBox .title {display: none;}.impowerBox .info {width: {width}px;}.status_icon {display: none}.impowerBox .status {text-align: center;} ",
    };
  },
  created() {
    // console.log("1111", window.location.href);
    if (window.location.search.substring(6).split("&")[0]) {
      this.loginWx(window.location.search.substring(6).split("&")[0]);
    }
  },
  methods: {
    show() {
      this.visible = true;
      this.$nextTick(() => {
        const width = this.$refs.vxqr.offsetWidth;
        const css = this.css.replaceAll("{width}", width);
        const href = Base64.encode(css);
        const vxlogin = new WxLogin({
          id: "vxqr",
          appid: "wxc8cb7d946e617297",
          scope: "snsapi_login",
          redirect_uri: "https://pc.hbsry.com",
          href: "data:text/css;base64," + href,
        });

        // 弹出式
        initAliyunCaptcha({
          SceneId: '1xbwlboq', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
          prefix: '19t15o', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
          mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
          element: '#captcha-element', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
          button: '#get-code', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
          captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
          onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
          getInstance: getInstance, // 绑定验证码实例函数，无需修改
          slideStyle: {
            width: 300,
            height: 40,
          }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
          language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
          region: 'cn' //验证码示例所属地区，支持中国内地（cn）、新加坡（sgp）
        })

        // 绑定验证码实例函数。该函数为固定写法，无需修改
        function getInstance(instance) {
          this.captcha = instance;
        }

        const that = this
        async function captchaVerifyCallback(captchaVerifyParam) {
          const verifyResult = {}
          verifyResult.captchaResult = await that.getCode(captchaVerifyParam)
          return verifyResult;
        }

        // 业务请求验证结果回调函数
        function onBizResultCallback(bizResult) {
          console.log(bizResult)
        }
      });
    },
    // 关闭
    colseLogin() {
      this.visible = false;
      this.$emit("closeLogin");
    },
    // 获取验证码
    async getCode(verifyParam) {
      if (this.phone == "") {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return true;
      }
      let that = this;

      const res = await api.GetCode({
          phone: this.phone,
          verifyParam: verifyParam
        })
      if (res.code == 200) {
        this.$message({
          message: "验证码发送成功！",
          type: "success",
        });
      }
      const result = res.data == null || res.data
      if(200 == res.data && result){
        this.codeTime = true;
        const interval = setInterval(function () {
          if (that.time === 1) {
            clearInterval(interval);
            that.codeTime = false;
          } else {
            // console.log(that.time)
            that.time--;
          }
        }, 1000);
      }
      
      return result
    },
    // 登录
    login() {
      if (this.phone == "") {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      }
      if (this.code == "") {
        this.$message({
          message: "请输入验证码",
          type: "warning",
        });
        return;
      }
      let data = {
        phone: this.phone,
        code: this.code,
      };
      api.loginPhone(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "登录成功！",
            type: "success",
          });
          localStorage.setItem("token_key", res.data.access_token);
          this.visible = false;
          // 关闭弹框
          this.$emit("closeLogin");
          window.location.reload();
        } else {
          this.$message({
            message:res.msg,
            type: "error",
          });
        }
      });
    },
    loginWx(code) {
      api
        .loginWx({
          code: code,
        })
        .then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.$message({
              message: "登录成功！",
              type: "success",
            });

            // 登录后删除code
            var currentUrl = window.location.href; // 获取当前URL
            // 构建一个新的URL，通过分割URL并过滤掉code参数
            var newUrl = currentUrl.split("?")[0]; // 获取不包含查询参数的URL部分
            window.history.replaceState({}, "", newUrl); // 使用新的URL替换当前历史记录

            localStorage.setItem("token_key", res.data.access_token);
          }
          // 关闭弹框
          this.$emit("closeLogin");
          window.location.reload();
        });
    },
  },
};
</script>
<style>
#vxqr {
  /* margin-top: 10%;
  margin-left: 10%;
  width: 80%;
  height: 80%; */
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
.login2 .el-dialog{
  background: none !important;
}

#login {
  height: 40rem;
  background: url("../assets/image/login_bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#login1 {
  width: 90%;
  height: 80%;
  padding: 1.5rem;
  background: url("../assets/image/login_bg1.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.ewm_title {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 1.5rem;
  color: #000000;
  margin-top: 1.2rem;
  margin-left: 1.2rem;
}
.ewm_box {
  margin-left: 3rem;
  margin-top: 2.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.ewm_box1 {
  width: 15rem;
  height: 15rem;
  background: url("../assets/image/login_ewmbg.png");
  background-size: 100% 100%;
  padding: 1rem;
  position: relative;
}
.ewm_text {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1.2rem;
  color: #333333;
  margin-top: 4rem;
}
.phone {
  width: 40%;
  margin-right: 3rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.phone_title {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 3rem;
  color: #3cadfd;
  background: linear-gradient(0deg, #2f81ed 0%, #2ee9d8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.phone_line {
  width: 3rem;
  height: 0.8rem;
  background: #b7d9ff;
  border-radius: 0.8rem;
  margin-bottom: 4rem;
  margin-top: 1rem;
}
.login_phone,
.login_password {
  width: 100%;
  height: 3.5rem;
  padding: 0 1rem;
  border-radius: 5rem;
  border: 0.1rem solid #eeeeee;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}
.login_phoneText {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1rem;
  color: #00b277;
  margin-right: 1rem;
}
.login_phone input,
.login_password input {
  border: none;
  /* 可选的样式，以去除焦点时的边框 */
  outline: none;
  /* background: red; */
  /* width: 70%; */
  font-size: 1.3rem;
  height: 3rem;
  line-height: 3rem;
}
.password {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.password img {
  width: 1rem;
  height: 1.5rem;
  margin-right: 1.5rem;
  margin-left: 0.4rem;
  padding-top: 0.5rem;
}
.time {
  width: 6rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1rem;
  color: #00b277;
}
.login_btn {
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  background: #0076fe;
  border-radius: 5rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1.8rem;
  color: #ffffff;
}
.colse {
  position: absolute;
  top: 1.3rem;
  right: 2rem;
}
.colse img {
  width: 1.6rem;
}
</style>