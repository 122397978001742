<template>
    <div>
      <div class="creation_main_title">{{ title }}</div>
  
      <creationAssembly2
        :content="content"
        @getContent="getContent1"
        :disabled="disabled"
      ></creationAssembly2>
      
      <creationAssembly2
        :content="content2"
        @getContent="getContent2"
        :disabled="disabled"
        inputType="number"
      ></creationAssembly2>
    </div>
  </template>
  <script>
  import creationAssembly2 from "@/components/creation_assembly2.vue";
  export default {
    props: {
      pageContent: {
        type: Object,
        default: {},
      },
      disabled: {
      type: Boolean,
      default: false,
    },
    },
    components: {
      creationAssembly2,
    },
    data() {
      return {
        title: "讲话心得",
  
        content: {
          title: "主要内容",
          placeholder: this.pageContent.content,
        },
        content2: {
          title: "生成文本字数",
          placeholder: this.pageContent.text_lenth,
        },
        //   请求信息
        contentInfo: {
          content: "",
          length: "",
        },
      };
    },
    created() {},
    methods: {
      getContent1(i) {
        this.contentInfo.content = i;
      },
      getContent2(i) {
        this.contentInfo.length = i;
      },
      getInfo() {
        if (this.contentInfo.content == "") {
          this.$message({
            message: "请输入主要内容",
            type: "warning",
          });
          return;
        }
        if (this.contentInfo.length == "") {
          this.$message({
            message: "请输入文本字数",
            type: "warning",
          });
          return;
        }
        let Arry = [];
        let detailInfo = [
          {
            title: "主要内容",
            text: this.contentInfo.content,
          },
          {
            title: "文本字数",
            text: this.contentInfo.length,
          },
        ];
        Arry.push(this.contentInfo);
        Arry.push(detailInfo);
        Arry.push({
          text: "讲话心得",
          url: "jhxd",
        });
        return Arry;
      },
    },
  };
  </script>
            <style>
  .creation_main_title {
    font-size: 1.5rem;
    color: #799097;
    padding-bottom: 1rem;
    border-bottom: 1px solid #799097;
  }
  </style>
            