<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>

    <creationAssembly2
      :content="content"
      @getContent="getContent1"
      :disabled="disabled"
    ></creationAssembly2>
  </div>
</template>
        <script>
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    creationAssembly2,
  },
  data() {
    return {
      title: "公文写作",

      content: {
        title: "公文标题",
        placeholder: this.pageContent.title,
      },
      //   请求信息
      contentInfo: {
        title: "",
      },
    };
  },
  created() {},
  methods: {
    getContent1(i) {
      this.contentInfo.title = i;
    },
    getInfo() {
      if (this.contentInfo.title == "") {
        this.$message({
          message: "请输入公文标题",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "公文标题",
          text: this.contentInfo.title,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "公文写作",
        url: "gwxz",
      });
      return Arry;
    },
  },
};
</script>
        <style>
.creation_main_title {
  font-size: 1.5rem;
  color: #799097;
  padding-bottom: 1rem;
  border-bottom: 1px solid #799097;
}
</style>
        