<template>
  <div class="dialogue">
    <div v-for="(item, i) in dialogueList" :key="i">
      <!-- 问题 -->
      <div class="question">
        <div class="question_box">
          {{ item.question }}
        </div>
        <div class="adialogue_head">
          <img src="../assets/logo.png" alt="" />
        </div>
      </div>

      <!-- 回答 -->
      <div class="answer">
        <div class="adialogue_head">
          <img src="../assets/image/Aitalk.png" alt="" />
        </div>
        <div class="answer_box">
          <div class="answer_text" v-if="item.anwser == ''">思考中...</div>
          <div class="answer_text" v-else>
             <!-- 打字机效果 -->
            <anwserText ref="anwserText" v-if="item.id == null" :content="item.anwser" @anwserOver="anwserOver"></anwserText>
            <!-- 正常显示 -->
            <div v-else v-html="item.anwser"></div>
          </div>
          <div class="answer_text1">(当前结果为AI合成，仅供参考)</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import anwserText from "@/components/anwser_text.vue";
export default {
  components: {
    anwserText,
  },
  data() {
    return {
      dialogueList: [],
    };
  },
  methods: {
    over() {
      this.$refs.anwserText[this.$refs.anwserText.length - 1].anwserOver()
    },
    getQuestion(data) {
      // console.log('对话记录',data)
      this.dialogueList =[]
      this.dialogueList = data;
    },
    anwserOver(){
      this.$emit("anwserOver", false);
    }
  },
};
</script>
<style>
.adialogue_head img {
  width: 4rem;
  margin-right: 1rem;
}
.question {
  display: flex;
  justify-content: end;
  margin-top: 1.5rem;
}
.question_box {
  padding: 0.8rem 1rem;
  border-radius: 1rem 0 1rem 1rem;
  background: #1c89ff;
  font-size: 1.2rem;
  color: #ffffff;
  margin-right: 1rem;
  max-width: 70%;
  display: flex;
  align-items: center;
}

.answer {
  margin-top: 1.5rem;
  display: flex;
}
.answer_box {
  width: 70%;
  padding: 0.8rem 1rem;
  border-radius: 1rem;
  background: #fff;
}
.answer_text {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
.answer_text1 {
  margin-top: 1rem;
  border-top: 1px solid #dddddd;
  padding-top: 0.8rem;
  color: #666666;
}
</style>