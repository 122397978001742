<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>

    <creationAssembly2
      :content="content"
      @getContent="getContent1"
      :disabled="disabled"
    ></creationAssembly2>
    
    <creationAssembly2
      :content="content2"
      @getContent="getContent2"
      :disabled="disabled"
    ></creationAssembly2>
  </div>
</template>
  <script>
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    creationAssembly2,
  },
  data() {
    return {
      title: "考核总结",

      content: {
        title: "考核主题",
        placeholder: this.pageContent.subject,
      },
      content2: {
        title: "考核重点",
        placeholder: this.pageContent.keynote,
      },
      //   请求信息
      contentInfo: {
        keynote: "",
        subject: "",
      },
    };
  },
  created() {},
  methods: {
    getContent1(i) {
      this.contentInfo.subject = i;
    },
    getContent2(i) {
      this.contentInfo.keynote = i;
    },
    getInfo() {
      if (this.contentInfo.subject == "") {
        this.$message({
          message: "请输入考核主题",
          type: "warning",
        });
        return;
      }
      if (this.contentInfo.keynote == "") {
        this.$message({
          message: "请输入考核重点",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "考核主题",
          text: this.contentInfo.subject,
        },
        {
          title: "考核重点",
          text: this.contentInfo.keynote,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "考核总结",
        url: "khzj",
      });
      return Arry;
    },
  },
};
</script>
            <style>
.creation_main_title {
  font-size: 1.5rem;
  color: #799097;
  padding-bottom: 1rem;
  border-bottom: 1px solid #799097;
}
</style>
            