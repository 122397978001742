<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>

    <creationAssembly1
      :content="content1"
      @getType="getType1"
      :disabled="disabled"
    ></creationAssembly1>

    <creationAssembly1
      :content="content2"
      @getType="getType2"
      :disabled="disabled"
    ></creationAssembly1>

    <creationAssembly2
      :content="content"
      @getContent="getContent1"
      :disabled="disabled"
    ></creationAssembly2>
  </div>
</template>
      <script>
import creationAssembly1 from "@/components/creation_assembly1.vue";
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    creationAssembly1,
    creationAssembly2,
  },
  data() {
    return {
      title: "新媒体写作",

      content: {
        title: "关键词",
        placeholder: this.pageContent.keyword,
      },
      content1: {
        title: "科目", //标题
        placeholder: "请输入自定义科目", // 文本框提示
        list: this.pageContent.course, //标签列表
      },
      content2: {
        title: "选择语气", //标题
        placeholder: "请输入自定义语气", // 文本框提示
        list: this.pageContent.tone, //标签列表
      },
      //   请求信息
      contentInfo: {
        keyword: "",
        course: "",
        tone: "",
      },
    };
  },
  created() {
    // console.log(this.pageContent)
    this.contentInfo.course = this.pageContent.course[0];
    this.contentInfo.tone = this.pageContent.tone[0];
  },
  methods: {
    getType1(i) {
      this.contentInfo.course = i;
    },
    getType2(i) {
      this.contentInfo.tone = i;
    },
    getContent1(i) {
      this.contentInfo.keyword = i;
    },
    getInfo() {
      if (this.contentInfo.keyword == "") {
        this.$message({
          message: "请输入关键词",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "科目",
          text: this.contentInfo.course,
        },
        {
          title: "语气",
          text: this.contentInfo.tone,
        },
        {
          title: "关键词",
          text: this.contentInfo.keyword,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "新媒体写作",
        url: "xmtxz",
      });
      return Arry;
    },
  },
};
</script>
      <style>
.creation_main_title {
  font-size: 1.5rem;
  color: #799097;
  padding-bottom: 1rem;
  border-bottom: 1px solid #799097;
}
</style>
      