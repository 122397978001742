<template>
  <div id="write_left">
    <div class="write_black" @click="black">
      <i class="el-icon-arrow-left"></i>
      返回
    </div>
    <div class="wrrite_lable">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="热门创作" name="1">
          <writeLeftbox :list="hotList" :initialId="initialId" @changeIndex="changeIndex"></writeLeftbox>
        </el-collapse-item>
        <el-collapse-item title="会员专区" name="2">
          <writeLeftbox :list="vipList" :initialId="initialId" @changeIndex="changeIndex"></writeLeftbox>
        </el-collapse-item>
        <el-collapse-item title="工作办公" name="3">
          <writeLeftbox width="2.5rem" :list="workList" :initialId="initialId" @changeIndex="changeIndex"></writeLeftbox>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import writeLeftbox from "@/components/write_left_box.vue";
import data from "@/assets/data";
export default {
  props: {
    leftData: {
      type: Object,
      default: {},
    },
  },
  components: {
    writeLeftbox,
  },
  created() {
    this.initialState(); //初始化
  },
  data() {
    return {
      activeNames: [], //当前激活的面板
      hotList: JSON.parse(localStorage.getItem("hotsList")),
      vipList: JSON.parse(localStorage.getItem("vipList")),
      workList: JSON.parse(localStorage.getItem("workList")),
      initialId:0, //初始id
    };
  },
  methods: {
    // 返回
    black() {
      this.$emit("black");
    },
    initialState() {
      // console.log('1111',this.leftData.item)
      if (this.leftData.title == "热门创作") {
        this.activeNames = ["1"];
      }
      if (this.leftData.title == "会员专区") {
        this.activeNames = ["2"];
      }
      if (this.leftData.title == "工作办公") {
        this.activeNames = ["3"];
      }
      this.initialId = this.leftData.item.id
    },
    changeIndex(item) {
      this.$emit("changType",item.type);
    },
  },
};
</script>
<style>
#write_left {
  height: 100%;
  overflow: auto;
  width: 16rem;
  background: #ffffff;
}
.write_black {
  font-size: 1.6rem;
  width: 90%;
  margin: 1rem auto;
  padding-top: 1rem;
  cursor: pointer;
  
}
.write_lable_title {
  font-size: 2rem;
  font-weight: 400;
  color: #0361ef;
  text-align: center;
  margin-bottom: 15px;
}
.el-collapse-item__header{
  font-size: 1.5rem !important;
  padding-left: 1rem;
  font-weight: 700 !important;
}
</style>