<template>
    <div>
      <div class="creation_main_title">{{ title }}</div>
      <creationAssembly2
        :content="content"
        @getContent="getContent"
        :disabled="disabled"
      ></creationAssembly2>

      <creationAssembly1
        :content="content1"
        @getType="getType1"
        :inputShow="false"
        :disabled="disabled"
      ></creationAssembly1>

      <creationAssembly2
        :content="content2"
        @getContent="getContent2"
        inputType="number"
        :disabled="disabled"
      ></creationAssembly2>

      <creationAssembly1
        :content="content3"
        @getType="getType3"
        :disabled="disabled"
      ></creationAssembly1>
    </div>
  </template>
      <script>
  import creationAssembly1 from "@/components/creation_assembly1.vue";
  import creationAssembly2 from "@/components/creation_assembly2.vue";
  export default {
    props: {
      pageContent: {
        type: Object,
        default: {},
      },
    disabled: {
      type: Boolean,
      default: false,
    },
    },
    components: {
      creationAssembly1,
      creationAssembly2,
    },
    data() {
      return {
        title: "写作文",
  
        content: {
          title: "作文题目",
          placeholder: this.pageContent.title,
        },
        content1: {
          title: "年级", //标题
          placeholder: "请输入自定义类型", // 文本框提示
          list: this.pageContent.grade, //标签列表
        },
        content2: {
          title: "生成文本字数",
          placeholder: this.pageContent.text_lenth,
        },
        content3: {
          title: "体载", //标题
          placeholder: "请输入自定义体载", // 文本框提示
          list: this.pageContent.theme, //标签列表
        },
        //   请求信息
        contentInfo: {
            title: "",
            grade: "",
            length: "",
            theme:''
        },
      };
    },
    created() {
      // console.log(this.pageContent)
      this.contentInfo.grade = this.pageContent.grade[0];
      this.contentInfo.theme = this.pageContent.theme[0];
    },
    methods: {
      getType1(i) {
        this.contentInfo.grade = i;
      },
      getType3(i) {
        this.contentInfo.theme = i;
      },
      getContent(i) {
        this.contentInfo.title = i;
      },
      getContent2(i) {
        this.contentInfo.length = i;
      },
      
      getInfo() {
        if (this.contentInfo.title == "") {
          this.$message({
            message: "请输入作文题目",
            type: "warning",
          });
          return;
        }
        if (this.contentInfo.length == "") {
          this.$message({
            message: "请输入文本字数",
            type: "warning",
          });
          return;
        }
        let Arry = [];
        let detailInfo = [
          {
            title: "作文题目",
            text: this.contentInfo.title,
          },
          {
            title: "年级",
            text: this.contentInfo.grade,
          },
          {
            title: "文本字数",
            text: this.contentInfo.length,
          },
          {
            title: "体载",
            text: this.contentInfo.theme,
          },
        ];
        Arry.push(this.contentInfo);
        Arry.push(detailInfo);
        Arry.push({
          text: "写作文",
          url: "xzw",
        });
        return Arry;
      },
    },
  };
  </script>
      <style>
  .creation_main_title {
    font-size: 1.5rem;
    color: #799097;
    padding-bottom: 1rem;
    border-bottom: 1px solid #799097;
  }
  </style>
      