<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>
    <creationAssembly2
      :content="content"
      @getContent="getContent1"
      :disabled="disabled"
    ></creationAssembly2>
    <creationAssembly1
      :content="content1"
      @getType="getType1"
      :inputShow="false"
      :disabled="disabled"
    ></creationAssembly1>
    <creationAssembly1
      :content="content2"
      @getType="getType2"
      :disabled="disabled"
    ></creationAssembly1>
  </div>
</template>
    <script>
import creationAssembly1 from "@/components/creation_assembly1.vue";
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    creationAssembly1,
    creationAssembly2,
  },
  data() {
    return {
      title: "写小说",

      content: {
        title: "主题",
        placeholder: this.pageContent.subject,
      },
      content1: {
        title: "类型", //标题
        placeholder: "请输入自定义类型", // 文本框提示
        list: this.pageContent.type, //标签列表
      },
      content2: {
        title: "风格", //标题
        placeholder: "请输入自定义风格", // 文本框提示
        list: this.pageContent.style, //标签列表
      },
      //   请求信息
      contentInfo: {
        subject: "",
        type: "",
        style: "",
      },
    };
  },
  created() {
    // console.log(this.pageContent)
    this.contentInfo.type = this.pageContent.type[0];
    this.contentInfo.style = this.pageContent.style[0];
  },
  methods: {
    getType1(i) {
      this.contentInfo.type = i;
    },
    getType2(i) {
      this.contentInfo.style = i;
    },
    getContent1(i) {
      this.contentInfo.subject = i;
    },
    getInfo() {
      if (this.contentInfo.subject == "") {
        this.$message({
          message: "请输入小说主题",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "主题",
          text: this.contentInfo.subject,
        },
        {
          title: "类型",
          text: this.contentInfo.type,
        },
        {
          title: "风格",
          text: this.contentInfo.style,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "写小说",
        url: "xxs",
      });
      return Arry;
    },
  },
};
</script>
    <style>
.creation_main_title {
  font-size: 1.5rem;
  color: #799097;
  padding-bottom: 1rem;
  border-bottom: 1px solid #799097;
}
</style>
    