<template>
  <div id="adviserTalk">
    <div>
      <div class="adviserTalk" ref="chatContainer">
        <!-- 引导 -->
        <div class="adviserTalk_answer" v-if="salutation">
          <div class="adialogue_head">
            <img :src="adviser.icon" alt="" />
          </div>
          <div class="adviserTalk_box">
            {{ adviser.salutation }}
          </div>
        </div>
        <!-- 对话 -->
        <div v-for="(item, i) in dialogueList" :key="i">
          <div class="adviserTalk_question">
            <div class="question">
              {{ item.question }}
            </div>
            <div class="adialogue_head">
              <img src="../assets/logo.png" alt="" />
            </div>
          </div>
          <div class="adviserTalk_answer">
            <div class="adialogue_head">
              <img :src="item.adviserIcon" alt="" />
            </div>
            <div class="adviserTalk_box">
              <div class="answer_text" v-if="item.anwser == ''">思考中...</div>
              <div class="answer_text" v-else>
                <!-- 打字机效果 -->
                <anwserText ref="anwserText" v-if="item.id == null" :content="item.anwser" @anwserOver="anwserOver"></anwserText>
                <!-- 正常显示 -->
                <div v-else v-html="item.anwser"></div>
              </div>
              <div class="answer_text1">(当前结果为AI合成，仅供参考)</div>
            </div>
          </div>
        </div>
      </div>

      <div class="adviserTalk_key">
        <el-input type="textarea" v-model="input" :placeholder="adviser.tips + '*Enter发送  Ctrl+Enter换行*'" @keydown.native="handleKeyCode($event)"></el-input>
        <div class="adviserTalk_btn" @click="send">发送</div>
      </div>
    </div>
    <div class="adviser_history">
      <history ref="history" @detailId="getId" type="adviser"></history>
    </div>
  </div>
</template>

<script>
import history from "@/components/history.vue";
import anwserText from "@/components/anwser_text.vue";
import api from "@/api/api";
export default {
  props: {
    adviser: {
      type: Object,
      default: {},
    },
  },
  components: {
    history,
    anwserText,
  },
  created() {
    this.newTalk();
  },
  data() {
    return {
      detailId: 0,
      sessionId: 0,
      dialogueList: [],
      input: "",
      latestScrollHeight: 0, // 用于跟踪最新滚动高度的变量
      salutation: true,
      nextanwser: false, //下一个问题
    };
  },
  methods: {
    newTalk() {
      api.createSession().then((res) => {
        if (res.code == 200) {
          this.sessionId = res.data;
        }
      });
    },
    handleKeyCode(event) {
      if (event.keyCode == 13) {
        if (!event.ctrlKey) {
          this.send()
          event.preventDefault(); 
        } else {
          this.input += '\n';
        }
      }
    },
    send() {
      if (this.input == "") {
        this.$message({
          message: this.adviser.tips,
          type: "warning",
        });
        return;
      }

      if (this.nextanwser) {
        this.$message({
          message: "请等待问题回答完成，再提问！",
          type: "warning",
        });
        return;
      }
      this.nextanwser = true;

      if (this.sessionId == 0) {
        this.$emit("openLogin");
        return;
      }
      let Obj = {
        question: this.input,
        sessionId: this.sessionId,
        adviser: this.adviser.type,
        anwser: "",
        adviserIcon: this.adviser.icon
      };
      this.input = "";
      this.dialogueList.push(Obj);

      this.getAnwser(Obj);
      this.latestScrollHeight = this.$refs.chatContainer.scrollHeight; // 更新最新滚动高度
      this.scrollToBottom();
    },
    // 流式请求获取回答
    async getAnwser(data) {
      // // fetch请求
      const token = localStorage.getItem("token_key");
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      };
      if (token == null) {
        headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
      }
      const options = {
        method: "POST", // 请求方法为POST
        body: new URLSearchParams(data).toString(), // 将可读流作为请求体
        // 其他选项，如headers等（如果需要）
        headers: headers,
      };
      // 使用fetch API发起请求，指定响应类型为response以获取ReadableStream对象
      const response = await fetch(
        process.env.VUE_APP_BASE_API + "assistant/app/session/question/stream",
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // 获取可读流读取器对象 循环
      const reader = response.body.getReader();
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decoder = new TextDecoder();
        const txt = decoder.decode(value);
        const t = this.getStr(txt);
        // console.log('f返回数据', t)
        if (t == "登录状态已过期") {
          this.$emit("openLogin");
        }
        this.dialogueList[this.dialogueList.length - 1].anwser =
          this.dialogueList[this.dialogueList.length - 1].anwser + t;
        this.latestScrollHeight = this.$refs.chatContainer.scrollHeight; // 更新最新滚动高度
        this.scrollToBottom();
      }
      this.over()
    },
    over() {
      this.$refs.anwserText[this.$refs.anwserText.length - 1].anwserOver()
    },
    // 返回值拼接
    getStr(str) {
      if (this.tmp) {
        str = this.tmp + str;
      }
      let arr = str.split("\n\n");
      let res = "";
      for (let s of arr) {
        if (!s || s == "") {
          continue;
        }
        try {
          let obj = JSON.parse(s);
          const code = obj.code;
          if ("401" == code) {
            this.$refs.login.show();
            return "";
          } else if ("700" == code) {
            break;
          } else if ("200" != code) {
            return obj.msg;
          } else {
            const data = obj.data.replace(/\n/g, "<br/>");
            res += data;
          }
          this.tmp = null;
        } catch (err) {
          console.log(err);
          if (this.tmp) {
            this.tmp = null;
          } else {
            this.tmp = str;
          }
        }
      }
      return res;
    },
    anwserOver() {
      // console.log("结束时");
      // 问题回答完毕
      this.nextanwser = false;
    },
    getId(item) {
      // console.log('11111---',item)
      this.adviser.icon = item.adviserIcon
      if (item.history) {
        this.pageNum = 1;
        this.dialogueList = [];
        this.salutation = false
      }
      this.$emit("changeTitle", item.title);
      this.sessionId = item.id;
      api.contentSession({
        sessionId: item.id,
        pageNum: this.pageNum,
        pageSize: 10,
      })
        .then((res) => {
          // console.log("对话记录", res);
          if (res.code == 200) {
            for (let i of res.data) {
              i.anwser = i.anwser.replace(/\n/g, "<br/>");
              i.adviserIcon = item.adviserIcon
            }
            this.dialogueList = this.dialogueList.concat(res.data.reverse());
          }
        });
      this.latestScrollHeight = this.$refs.chatContainer.scrollHeight; // 更新最新滚动高度
      this.scrollToBottom();
    },

    scrollToBottom() {
      // 使用Vue的$nextTick确保DOM更新完成后再进行滚动操作
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
      });
    },
  },
};
</script>

<style>
#adviserTalk {
  position: relative;
  height: 73vh;
  display: flex;
  justify-content: space-between;
}

.adviserTalk {
  width: 100rem;
  height: 70vh;
  overflow: auto;
}

.adialogue_head img {
  width: 4rem;
  height: 4rem;
}

.adviserTalk_answer {
  display: flex;
  margin-bottom: 1rem;
}

.adviserTalk_question {
  display: flex;
  margin-bottom: 1rem;
  justify-content: end;
}

.adviserTalk_box {
  font-size: 1.2rem;
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 1rem;
  display: flex;
  flex-flow: column;
  /* align-items: center; */
  margin-left: 1rem;
  max-width: 40%;
}
.answer_text{
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
.answer_text1 {
  width: 100%;
  margin-top: 1rem;
  border-top: 1px solid #dddddd;
  padding-top: 0.8rem;
  color: #666666;
}

#adviserTalk .question {
  font-size: 1.2rem;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  background: #1c89ff;
  color: #fff;
  margin: 0;
  margin-right: 1rem;
  max-width: 40%;
}

.adviserTalk_key {
  position: absolute;
  bottom: 0;
  width: 100rem;
  height: 5vh;
  display: flex;
  align-items: center;
}

.adviserTalk_btn {
  width: 7rem;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  background: #35a2ff;
  margin-left: 2rem;
  border-radius: 1rem;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
}

.adviser_history {
  height: 75vh;
  overflow: auto;
  position: absolute;
  top: -5rem;
  right: 0;
}

.adviser_history #history {
  margin-top: 0;
}
</style>