import { render, staticRenderFns } from "./fxbg.vue?vue&type=template&id=0d585c38"
import script from "./fxbg.vue?vue&type=script&lang=js"
export * from "./fxbg.vue?vue&type=script&lang=js"
import style0 from "./fxbg.vue?vue&type=style&index=0&id=0d585c38&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports