<template>
  <div class="keyboard">
    <div v-if="userInfo.vipType == 'experience'" class="keyboard_num">剩余字符{{ userInfo.surplusTokens }}</div>
    <div v-if="userInfo.vipType == 'common'" class="keyboard_num">免费次数{{ freeNum }}</div>
    <div class="keyboard_box">
      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" v-model="question" placeholder="请输入你的问题 *Enter发送  Ctrl+Enter换行*"
        @keydown.native="handleKeyCode($event)"></el-input>
      <img @click="send" src="../assets/image/send.png" alt="">
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
export default {
  props: {
    userInfo: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      question: "", //问题
      freeNum: 0, // 免费次数
      // userInfo:{}
    };
  },
  created() {
    this.getFreeNum()
    // if (JSON.parse(localStorage.getItem("userInfo"))) {
    //   this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // }
    this.$root.$on('user-refreshed', () => {
      this.getFreeNum()
      if (JSON.parse(localStorage.getItem("userInfo"))) {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      }
    })
  },
  methods: {
    handleKeyCode(event) {
      if (event.keyCode == 13) {
        if (!event.ctrlKey) {
          this.send()
          event.preventDefault();
        } else {
          this.question += '\n';
        }
      }
    },
    send() {
      if(this.question == ''){
        this.$message({
          message: "请输入你的问题",
          type: "warning",
        });
        return;
      }
      this.$emit("choiceQuestion", this.question);
      this.question = "";
    },
    getFreeNum() {
      api.degree().then((res) => {
        // console.log(res);
        this.freeNum = res.data
      });
    },
    getNum() {
      // this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.getFreeNum()
    }
  },
};
</script>

<style>
.keyboard {
  width: 100%;
  margin: 0 auto;
}

.keyboard_num {
  width: 8rem;
  text-align: center;
  color: #fff;
  padding: 0.8rem 1rem;
  margin-bottom: 0.8rem;
  border-radius: 0 1rem 1rem 1rem;
  background: #1c89ff;
}

.keyboard_box {
  position: relative;
}

.el-textarea__inner {
  padding-right: 5.5rem !important;
}

.keyboard_box img {
  width: 2.8rem;
  height: 2rem;
  position: absolute;
  right: 1.3rem;
  bottom: 0.5rem;
}
</style>