<template>
  <div id="assembly2">
    <div class="assembly_title">{{ content.title }}</div>
    <el-input
      :type="inputType"
      :placeholder="content.placeholder"
      v-model="textarea"
      :maxlength="maxNum == 0 ? '' : maxNum"
      @change="change"
      :autosize="true"
      :disabled="disabled"
      :min="0"
    >
    </el-input>
    <div v-if="maxNum" class="assembly_num">
      {{ textarea.length }}/{{ maxNum }}
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    content: {
      type: Object,
      default: {},
    },
    maxNum: {
      type: Number,
      default: 0,
    },
    inputType:{
      type: String,
      default: 'textarea',
    },
    //是否禁用
    disabled:{
      type: Boolean,
      default: false,
    },
  },
  created() {
    // this.maxlength = this.maxNum
  },
  data() {
    return {
      // content:{}
      textarea: "",
    };
  },
  methods: {
    change() {
      if(this.inputType == 'number'){
        if(this.textarea <= 0){
          this.$message.error("请输入正确字数！");
          this.textarea = ''
          return
        }
      }
      this.$emit("getContent", this.textarea)
    },
  },
};
</script>
  <style>
#assembly2 {
  position: relative;
}
#assembly2 .assembly_title{
  font-size: 1.2rem;
  color: #333;
  padding: 1rem 0;
}
.assembly_num {
  position: absolute;
  right: 1.6rem;
  bottom: 0.3rem;
  font-size: 1.2rem;
}
#assembly2 .el-textarea__inner {
  padding: 1rem 0.8rem;
  font-size: 1rem !important;
  padding-bottom: 2rem !important;
  padding-right: 0.8rem !important;
}
</style>