<template>
  <div>
    <div id="Aitalk">
      <!-- 左边导航 -->
      <aiTalkBtn
        ref="userInfo"
        @changeIndex="changeIndex"
        @openLogin="openLogin"
      ></aiTalkBtn>
      <div>
        <!-- 头部导航栏 -->
        <nav-bar @getnavIn="getnavIn"></nav-bar>
        <!-- ai写作 -->
        <ai-writing v-if="navIndex == 0"></ai-writing>
        <!-- ai顾问 -->
        <ai-adviser v-if="navIndex == 2" @openLogin="openLogin"></ai-adviser>
        <!-- ai对话 -->
        <div v-if="navIndex == 1" class="Aitalk">
          <talk ref="aiTalk" @openLogin="openLogin"></talk>
          <!-- 历史记录 -->
          <history
            ref="history"
            @detailId="detailId"
            @newTalk="changeIndex(2)"
            type="talk"
          ></history>
        </div>
      </div>
      <div class="filings" >
        <a href="https://beian.miit.gov.cn" class="contact3"> 鄂ICP备2023031487号 </a>
        <div class="contact2">Copyright © 2023 - 2025 hbsry.com 版权所有</div>
      </div>
    </div>

    <!-- vip弹框 -->
    <!-- <vip ref="vipUser" :vipShow="vipShow" @closeVip="closeVip"></vip> -->

    <!-- 登录弹框 -->
    <login ref="login" @closeLogin="closeLogin"></login>
  </div>
</template>

<script>
import aiTalkBtn from "@/components/aiTalk_btn.vue";
import talk from "@/components/talk.vue";
import vip from "@/components/vip.vue";
import login from "@/components/login.vue";
import history from "@/components/history.vue";
import navBar from "@/components/nav_bar.vue";
import aiWriting from "@/components/ai_writing.vue";
import aiAdviser from "@/components/ai_adviser.vue";
import api from "@/api/api";
export default {
  components: {
    aiTalkBtn,
    talk,
    vip,
    history,
    login,
    navBar,
    aiWriting,
    aiAdviser
  },
  data() {
    return {
      vipShow: false, //vip弹框
      loginShow: false, //登录弹框
      historyList: {}, //  历史记录列表
      navIndex:0, //头部index
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getnavIn(i){
      this.navIndex = i
      this.$refs.userInfo.changeLogo(i)
    },
    changeIndex(i) {
      // if (i == 1) {
      //   this.vipShow = true;
      // }
      if (i == 2) {
        this.$refs.aiTalk.newTalk("new");
      }
    },
    // 关闭vip弹框
    closeVip() {
      this.vipShow = false;
    },
    // 打开登录弹框
    openLogin() {
      this.$refs.login.show();
    },
    // 关闭登录弹框
    closeLogin() {
      this.loginShow = false;
      this.$refs.aiTalk.newTalk("new");
      this.getUser();
    },
    getUser() {
      // 获取用户信息
      api.getUserinfo().then((res) => {
        if (res.code == 200) {
          // console.log(res.data)
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$refs.userInfo.getUserinfo(res.data);
          // this.$refs.vipUser.getUserinfo(res.data);
          if(this.navIndex == 1){
            this.$refs.history.getHistory();
          }
        } else if (res.code == 401) {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token_key");
        }
      });
    },
    detailId(id) {
      this.$refs.aiTalk.getId(id);
    },
  },
};
</script>
<style>
#Aitalk {
  width: 100%;
  height: 100vh;
  background: url(../assets/image/Aitalk_bg.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
}
.Aitalk{
  width: 120rem;
  height: 94%;
  display: flex;
  justify-content: space-between;
}
.filings{
  position: absolute;
  text-align: center;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%);
}
.contact2 {
    font-size: 1.3rem;
    color: #4d1a8b;
}
.contact3{
  font-size: 1.3rem;
    margin-bottom: 1rem;
    color: #4d1a8b;
}
</style>