<template>
  <div id="assembly1">
    <div class="assembly_title">{{ content.title }}</div>
    <div class="assembly_lable">
      <div
        :class="
          lableIndex == i ? 'assembly_box assembly_ubox' : 'assembly_box'
        "
        v-for="(item,i) in content.list"
        :key="i"
        @click="getLable(i)"
      >
        {{ item }}
      </div>
    </div>

    <el-input
      v-if="inputShow"
      v-model="input"
      :placeholder="content.placeholder"
      :maxlength="maxNum == 0 ? '' : maxNum"
      @input="custom"
      @change="change"
      :disabled="disabled"
    ></el-input>
    <div v-if="inputShow" class="assembly1_num">
      {{ input.length }}/{{ maxNum }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content:{
      type: Object,
      default: {},
    },
    maxNum: {
      type: Number,
      default: 20,
    },
    inputShow:{
      type: Boolean,
      default: true,
    },
    //是否禁用
    disabled:{
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      lableIndex: 0,
      input: "",
    };
  },
  methods: {
    getLable(i) {
      if(this.disabled){
        return
      }
      this.lableIndex = i;
      this.input = ''
      this.$emit("getType",this.content.list[i]);
    },
    custom(value) {
      if (value !== "") {
        this.lableIndex = -1;
      } else {
        this.lableIndex = 0;
        this.$emit("getType",this.content.list[0]);
      }
    },
    change(){
      this.$emit("getType", this.input);
    }
  },
};
</script>
<style>
#assembly1 {
  position: relative;
}
#assembly1 .assembly_title {
  font-size: 1.2rem;
  color: #333;
  padding: 1rem 0;
}
.assembly_lable {
  display: flex;
  flex-wrap: wrap;
}
.assembly_box {
  font-size: 1.3rem;
  padding: 0.8rem 1.2rem;
  border-radius: 0.5rem;
  color: #799097;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
  border: 1px solid #799097;
  cursor: pointer;
}
.assembly_ubox {
  color: #ffffff;
  background: #409eff;
  border: 1px solid #409eff;
}
.el-input__inner {
  padding: 0 1rem !important;
  height: 4rem !important;
  font-size: 1rem !important;
  padding-right: 4rem !important;
}
.assembly1_num {
  position: absolute;
  right: 0.8rem;
  bottom: 1.3rem;
}
</style>