<template>
  <div id="talk">

    <div id="scroll-talk" ref="chatContainer">
      <talkTop @choiceQuestion="askQuestion"></talkTop>
      <dialogue ref="talkQuestion" @anwserOver="anwserOver"></dialogue>
    </div>
    <!-- 键盘 -->
    <div class="keyboard">
      <keyboard @choiceQuestion="askQuestion" ref="keyboard" :userInfo="userInfo"></keyboard>
    </div>
  </div>
</template>
<script>
import talkTop from "@/components/talk_top.vue";
import dialogue from "@/components/dialogue.vue";
import keyboard from "@/components/keyboard.vue";
import api from "@/api/api";

export default {
  components: {
    talkTop,
    dialogue,
    keyboard,
  },
  data() {
    return {
      talkList: [], //ai对话内容
      sessionId: 0, //会话id
      pageNum: 1,
      latestScrollHeight: 0, // 用于跟踪最新滚动高度的变量
      nextanwser: false, //下一个问题
      tmp: null,
      userInfo:{}
    };
  },
  created() {
    this.newTalk();
  },
  methods: {
    // 创建新对话
    newTalk(i) {
      if (i == "new") {
        this.talkList = [];
        this.$refs.talkQuestion.getQuestion(this.talkList);
      }
      api.createSession().then((res) => {
        if (res.code == 200) {
          if (i == "new") {
            this.$message({
              message: "新对话已创建",
              type: "success",
            });
          }
          this.sessionId = res.data;
        }
      });
    },
    anwserOver() {
      // 问题回答完毕
      this.nextanwser = false;

    },

    // 提出问题
    askQuestion(item) {
      if (this.nextanwser) {
        this.$message({
          message: "请等待问题回答完成，再提问！",
          type: "warning",
        });
        return;
      }

      this.nextanwser = true;
      let arry = {
        question: item,
        sessionId: this.sessionId,
        anwser: "",
      };
      this.talkList.push(arry);
      if (this.sessionId == 0) {
        this.$emit("openLogin");
        return;
      }
      this.getAnwser(arry);
      this.$refs.talkQuestion.getQuestion(this.talkList);
      this.latestScrollHeight = this.$refs.chatContainer.scrollHeight; // 更新最新滚动高度
      this.scrollToBottom();
    },
    // 流式请求获取回答
    async getAnwser(data) {
      // // fetch请求
      const token = localStorage.getItem("token_key");
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      };
      if (token == null) {
        headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
      }
      data.platform = 2
      const options = {
        method: "POST", // 请求方法为POST
        body: new URLSearchParams(data).toString(), // 将可读流作为请求体
        // 其他选项，如headers等（如果需要）
        headers: headers,
      };
      // 使用fetch API发起请求，指定响应类型为response以获取ReadableStream对象
      const response = await fetch(
        process.env.VUE_APP_BASE_API + "assistant/app/session/question/stream",
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // 获取可读流读取器对象 循环
      const reader = response.body.getReader();
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decoder = new TextDecoder();
        const txt = decoder.decode(value);
        const t = this.getStr(txt)
        if (t == "登录状态已过期") {
          this.$emit("openLogin");
        }
        this.talkList[this.talkList.length - 1].anwser =
          this.talkList[this.talkList.length - 1].anwser + t;
        // console.log(this.talkList)
        this.$refs.talkQuestion.getQuestion(this.talkList);
        this.latestScrollHeight = this.$refs.chatContainer.scrollHeight; // 更新最新滚动高度
        this.scrollToBottom();
        this.getUser();
      }
      this.$refs.talkQuestion.over()
      this.getUserinfo()
    },
    // 返回值拼接
    getStr(str) {
      if(this.tmp){
        str = this.tmp + str
      }
      let arr = str.split("\n\n");
      let res = "";
      for (let s of arr) {
        if (!s || s == "") {
          continue;
        }
        try {
          let obj = JSON.parse(s);
          const code = obj.code;
          if ("401" == code) {
            this.$refs.login.show();
            return "";
          } else if ("700" == code) {
            break;
          } else if ("200" != code) {
            return obj.msg;
          } else {
            const data = obj.data.replace(/\n/g, "<br/>");
            res += data;
          }
          this.tmp = null;
        } catch (err) {
          console.log(err);
          if(this.tmp){
            this.tmp = null
          }else{
            this.tmp = str
          }
        }
      }
      return res;
    },
    getId(item) {
      // console.log('获取id',item)
      if (item.history) {
        this.pageNum = 1;
        this.talkList = [];
      }
      this.sessionId = item.id;
      api
        .contentSession({
          sessionId: item.id,
          pageNum: this.pageNum,
          pageSize: 10,
        })
        .then((res) => {
          // console.log("对话记录", res);
          if (res.code == 200) {
            for (let item of res.data) {
              item.anwser = item.anwser.replace(/\n/g, "<br/>");
            }
            this.talkList = this.talkList.concat(res.data.reverse());
          }
          this.$refs.talkQuestion.getQuestion(this.talkList);
        });
      this.latestScrollHeight = this.$refs.chatContainer.scrollHeight; // 更新最新滚动高度
      this.scrollToBottom();
    },
    // 点击登陆，显示个人信息
    getUser() {
      // 获取用户信息
      api.getUserinfo().then((res) => {
        if (res.code == 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.userInfo = res.data
          this.$refs.keyboard.getNum();
        }
      });
    },
    scrollToBottom() {
      // 使用Vue的$nextTick确保DOM更新完成后再进行滚动操作
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
      });
    },
    getUserinfo(){
      api.getUserinfo().then((res) => {
        if (res.code == 200) {
          // console.log(res.data);
          this.userInfo = res.data
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$root.$emit('user-refreshed')
        }
      });
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
#scroll-talk {
  overflow: auto;
  height: 80%;
  margin-top: 2.5rem;
}
#talk {
  width: 75%;
  height: 92%;
  padding-top: 2rem;
  position: relative;
}
#talk ul {
  padding: 0 !important;
}
.keyboard {
  position: absolute;
  bottom: 2vh;
}
</style>