<template>
  <div id="result">
    <!-- top切换 -->
    <div class="result_top">
      <div
        :class="
          topIndex == i ? 'result_top_box result_top_ubox' : 'result_top_box'
        "
        v-for="(item, i) in topLable"
        :key="i"
        @click="changeTop(i, [], true)"
      >
        {{ item }}
      </div>
    </div>
    <!-- 本次创作结果 -->
    <div v-if="topIndex === 0" style="height: 94%">
      <!-- <div v-if="historyShow" v-html="answer"></div>
      <div v-else></div> -->
      <div v-if="requestDatas.length == 0">
        <div style="height: 6rem"></div>
        <noData
          width="15rem"
          text="创作结果将在这里展示，请完善你要生成的内容吧！"
        ></noData>
      </div>
      <resultContent
        v-else
        :content="requestDatas"
        :anwser="anwser"
        @anwserOver="anwserOver"
        ref="resultContent"
        :his="historyShow"
      ></resultContent>
    </div>
    <div v-loading.fullscreen.lock="fullscreenLoading"></div>
    <!-- 历史记录 -->
    <div v-if="topIndex === 1">
      <resultHistory></resultHistory>
    </div>
    <!-- 登录弹框 -->
    <login ref="login" @closeLogin="closeLogin"></login>
  </div>
</template>
<script>
import noData from "@/components/noData.vue";
import resultContent from "@/components/result_content.vue";
import resultHistory from "@/components/result_history.vue";
import login from "@/components/login.vue";
import api from "@/api/api";
export default {
  components: {
    login,
    noData,
    resultContent,
    resultHistory,
  },
  props: {
    requestData: {
      type: Array,
      default: [],
    },
    empty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      topLable: ["创作结果", "历史记录"],
      topIndex: 0, // d顶部切换
      requestDatas: this.requestData,
      historyShow: false, // 历史记录过来
      anwser: "", //历史记录回答
      tmp: null,
      fullscreenLoading: false,
    };
  },
  created(){
    if(!this.empty){
      this.topIndex =0
      this.requestDatas = [];
    }
  },
  methods: {
    changeTop(i, item,his) {
      // console.log(i,item)
      this.topIndex = i;
      if(his){
        this.historyShow = true
      }
      if (!his) {
        this.historyShow = false
        this.requestDatas = item;
        this.anwser = "";
        if (item.length !== 0) {
          this.fullscreenLoading = true;
          this.getAnwser();
        }
      }
    },
    // 流式请求获取回答
    async getAnwser() {
      // // fetch请求
      const token = localStorage.getItem("token_key");
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      };
      if (token == null) {
        headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
      }
      this.requestDatas[0].platform = 2
      const options = {
        method: "POST", // 请求方法为POST
        body: new URLSearchParams(this.requestDatas[0]).toString(), // 将可读流作为请求体
        // 其他选项，如headers等（如果需要）
        headers: headers,
      };
      // 使用fetch API发起请求，指定响应类型为response以获取ReadableStream对象
      const response = await fetch(
        process.env.VUE_APP_BASE_API +
          "assistant/app/question/stream/" +
          this.requestDatas[2].url,
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // 获取可读流读取器对象 循环
      const reader = response.body.getReader();

      while (true) {
        this.fullscreenLoading = false;
        const { done, value } = await reader.read();
        if (done) break;
        const decoder = new TextDecoder();
        const txt = decoder.decode(value);
        const t = this.getStr(txt);
        // console.log('t',t)
        if (t == "登录状态已过期") {
          this.$refs.login.show();
        }
        if(t && t != ''){
          this.anwser = this.anwser + t;
        }
      }
      this.$refs.resultContent.over()
      this.closeLogin()
    },
    // 返回值拼接
    getStr(str) {
      // console.log('str',str)
      if (this.tmp) {
        str = this.tmp + str;
      }
      let arr = str.split("\n\n");
      
      let res = "";
      for (let s of arr) {
        if (!s || s == "") {
          continue;
        }
        try {
          let obj = JSON.parse(s);
          const code = obj.code;
          if ("401" == code) {
            this.$refs.login.show();
            return "";
          } else if ("700" == code) {
            break;
          } else if ("200" != code) {
            return obj.msg;
          } else {
            const data = obj.data.replace(/\n/g, "<br/>");
            res += data;
          }
          this.tmp = null;
        } catch (err) {
          console.log(err);
          if(this.tmp){
            this.tmp = null
          }else{
            this.tmp = str
          }
        }
      }
      return res;
    },
    closeLogin() {
      // 获取用户信息
      api.getUserinfo().then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          // this.$refs.userInfo.getUserinfo(res.data);
          this.$root.$emit('user-refreshed')
        }
      });
    },
    anwserOver() {
      this.$emit("anwserOver");
    },
  },
};
</script>
<style>
#result {
  width: 75rem;
  padding-top: 2%;
}
.result_top {
  display: flex;
  border-bottom: 1px solid #799097;
}
.result_top_box {
  font-size: 1.3rem;
  color: #799097;
  padding-bottom: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}
.result_top_ubox {
  color: #409eff;
  border-bottom: 2px solid #2290ff;
}
.result_top_box:hover {
  color: #409eff;
}
</style>