<template>
  <div id="btn">
    <div id="top">
      <!-- logo图标 -->
      <div id="logo">
        <img @click="navto" src="@/assets/logo.png" alt="" />
        <div class="logo_text">{{logoText}}</div>
      </div>

      <!-- 个人信息 -->
      <div id="Avatar" @click="getUser()">
        <el-popover
          placement="right"
          popper-class="el-userInfo"
          v-model="visible"
        >
          <!-- 个人信息 -->
          <user-info :userInfo="userInfo" @openVip="openVip"></user-info>

          <div class="user_head" slot="reference">
            <img src="../assets/logo.png" alt="" />
          </div>
        </el-popover>
        <div class="vip_state">
          <img
            v-if="userInfo && userInfo.vip"
            src="../assets/image/vip_state1.png"
            alt=""
          />
          <img v-else src="../assets/image/vip_state.png" alt="" />
        </div>
        <div id="user_name">
          {{ userInfo?.nickname ? userInfo.nickname : "未登录" }}
        </div>
      </div>

      <!-- 功能列表 -->
      <!-- <div class="box" v-for="(item, i) in list" :key="i" @click="change(i)">
        <img :src="item.img" alt="" />
        <div :class="lableIndex == i ? 'text utext' : 'text'">
          {{ item.text }}
        </div>
      </div> -->

      <div class="box" @click="change(2)" v-if="newdialog">
        <img src="../assets/image/Aitalk1.png" alt="" />
        <div class="text">新建对话</div>
      </div>
      <div class="box" v-popover:morePop>
        <img src="../assets/image/more.png" alt="" />
        <div class="text">更多</div>
      </div>

      <!-- 更多 -->
      <el-popover
        placement="right"
        ref="morePop"
        popper-class="el-popover-more"
      >
        <img class="more_img" src="../assets/image/more1.png" alt="">
        <div class="more_text">更多功能陆续更新中尽请期待！</div>
      </el-popover>
    </div>

    <!-- 设置 -->
    <div id="set">
      <img
        v-popover:setPop
        slot="reference"
        src="../assets/image/set.png"
        alt=""
      />
      <!-- 设置弹框 -->
      <el-popover
        placement="right"
        width="160"
        ref="setPop"
        popper-class="el-popover-self"
      >
        <set></set>
      </el-popover>
    </div>

    <!-- 下载 -->
    <!-- <el-dialog
      :destroy-on-close='true'
      :visible.sync="download"
      width="200px"
      :show-close="false"
    >
      <div id="download">
        <div id="download_ewm">
          <div class="download_ewm" ref="qrcode"></div>
        </div>
        <div class="download_text">手机扫描二维码下载</div>
        <div class="download_btn">
          <img src="../assets/image/download_btn.png" alt="" />
          安卓下载
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import data from "../assets/data.js";
import api from "@/api/api";
import userInfo from "@/components/user_info.vue";
import set from "@/components/set.vue";
// 二维码
import QRcode from "qrcodejs2";
export default {
  components: {
    userInfo,
    set,
    QRcode,
  },
  data() {
    return {
      list: data.data.Aibtn, //功能列表
      lableIndex: 0, //左边选中
      visible: false, //个人信息弹框
      vipEquity: data.data.vipEquity, //vip权益
      qrCodeValue: "",
      userInfo: null,
      download: false,
      logoText:'Ai写作',
      newdialog:false,
    };
  },
  created() {
    if (JSON.parse(localStorage.getItem("userInfo"))) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
    // this.getEwm()
  },
  methods: {
    navto() {
      // this.$router.push("/");
    },
    changeLogo(i){
      // console.log('i',i)
      this.newdialog = false
      if(i == 0){
        this.logoText = 'Ai写作'
      }
      if(i == 1){
        this.logoText = 'Ai对话'
        this.newdialog = true
      }
      if(i == 2){
        this.logoText = 'Ai顾问'
      }
    },
    // 获取下载二维码
    getEwm() {
      api.getApk().then((res) => {
        if (res.code == 200) {
          this.$nextTick(() => {
            new QRcode(this.$refs.qrcode, res.data);
          });
        }
      });
    },
    change(i) {
      this.$emit("changeIndex", i);
      // this.lableIndex = i;
      // if (i == 1 || i == 3) {
      //   this.download = true;
      //   this.getEwm();
      // }
    },
    // 事件传值
    getUserinfo(data) {
      this.userInfo = data;
    },
    // 点击登陆，显示个人信息
    getUser() {
      // 获取用户信息
      api.getUserinfo().then((res) => {
        if (res.code == 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.userInfo = res.data;
          this.visible = true;
        } else if (res.code == 401) {
          this.$emit("openLogin");
        }
      });
    },
    openVip() {
      this.visible = false;
      // this.$emit("changeIndex", 1);
      this.download = true;
      this.getEwm();
    }
  },
};
</script>
<style>
#btn {
  width: 5rem;
  height: 92%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 1.5rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  background: #ebeaf5;
  margin-top: 2%;
  margin-left: 0.8rem;
}
#logo {
  padding: 1.2rem 0 1rem;
  text-align: center;
  width: 4rem;
  border-bottom: 1px solid #d6cfe2;
  margin: 0 auto;
  /* background: #FFF; */
}
#logo img {
  width: 100%;
}
.logo_text {
  font-size: 1rem;
  margin-top: 0.5rem;
  cursor: pointer;
}
.box {
  margin-bottom: 1.2rem;
  padding-top: 0.8rem;
  text-align: center;
  /* background: #FFF; */
}
.box img {
  width: 3.2rem;
}
.box .text {
  font-size: 1rem;
  margin-top: 5px;
  font-weight: 500;
  color: #554c87;
  cursor: pointer;
}
.box .utext {
  color: #335aff;
  cursor: pointer;
}
#Avatar {
  width: 100%;
  margin: 0 auto;
  position: relative;
  /* background: #335AFF; */
}
.user_head {
  width: 3.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 4rem;
  margin-top: 1rem;
}
.user_head img {
  width: 100%;
}
.vip_state {
  width: 90%;
  position: absolute;
  bottom: -1.2rem;
  left: 50%;
  transform: translate(-53%);
}
.vip_state img {
  width: 100%;
}
#user_name {
  width: 100%;
  text-align: center;
  /* background: red; */
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 1rem;
  color: #333333;
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translate(-50%);
  white-space: nowrap; /* 对于单行文本 */
  overflow: hidden;
  text-overflow: ellipsis; /* 显示省略号 */
  cursor: pointer;
}

.el-popover {
  padding: 0 !important;
  background: #fff;
}
.userInfo_top {
  padding: 1rem;
}
.userInfo_top .name {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
}
.userInfo_top .vip {
  font-size: 1.2rem;
  color: #fff0da;
  margin: 5px 0 0;
}
.lable_title {
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff0da;
  text-align: center;
  margin-bottom: 10px;
}
#set {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
#set img {
  width: 3.2rem;
}
#download {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 10px 0;
}
.download_ewm img {
  width: 180px !important;
  height: 180px !important;
  /* background: blue; */
}
.download_text {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  margin: 10px 0;
}
.download_btn {
  width: 125px;
  height: 35px;
  line-height: 35px;
  background: #1c89ff;
  border-radius: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download_btn img {
  width: 16px;
  height: 20px;
  margin-right: 8px;
}
.el-popover.el-popper.el-popover-self {
  min-width: 13rem !important;
  width: 16rem !important;
  padding: 0;
}
.el-userInfo {
  min-width: 20rem !important;
  width: 26rem !important;
  padding: 0;
}
.el-popover-more{
  min-width: 10rem !important;
  width: 12rem !important;
  padding: 1rem !important;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.more_img{
  width: 9rem;
  height: 6rem;
}
.more_text{
  width: 10rem;
  font-size: 1.2rem;
  color: #333333;
}
</style>