<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>

    <creationAssembly1
      :content="content"
      @getType="getType"
      :disabled="disabled"
    ></creationAssembly1>

    <creationAssembly2
      :content="content1"
      @getContent="getContent1"
      :disabled="disabled"
    ></creationAssembly2>

    <creationAssembly2
      :content="content2"
      @getContent="getContent2"
      :disabled="disabled"
    ></creationAssembly2>
  </div>
</template>
    <script>
import creationAssembly1 from "@/components/creation_assembly1.vue";
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    creationAssembly1,
    creationAssembly2,
  },
  data() {
    return {
      title: "例行报告",
      content: {
        title: "类型", //标题
        placeholder: "请输入自定义类型", // 文本框提示
        list: this.pageContent.type, //标签列表
      },
      content1: {
        title: "工作内容",
        placeholder: this.pageContent.content,
      },
      content2: {
        title: "职业(非必填)",
        placeholder: this.pageContent.occupation,
      },
      //   请求信息
      contentInfo: {
        style: "",
        content: "",
        occupation: "",
      },
    };
  },
  created() {
    this.contentInfo.type = this.pageContent.type[0];
  },
  methods: {
    getType(i) {
      this.contentInfo.type = i;
    },
    getContent1(i) {
      this.contentInfo.content = i;
    },
    getContent2(i) {
      this.contentInfo.occupation = i;
    },
    getInfo() {
      if (this.contentInfo.content == "") {
        this.$message({
          message: "请输入工作内容",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "类型",
          text: this.contentInfo.type,
        },
        {
          title: "工作内容",
          text: this.contentInfo.content,
        },
        {
          title: "职业",
          text: this.contentInfo.occupation,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "例行报告",
        url: "lxbg",
      });
      return Arry;
    },
  },
};
</script>
    <style>
.creation_main_title {
  font-size: 1.5rem;
  color: #799097;
  padding-bottom: 1rem;
  border-bottom: 1px solid #799097;
}
</style>
    