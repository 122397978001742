<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>

    <creationAssembly2
      :content="content1"
      @getContent="getContent1"
      :maxNum="2000"
      :disabled="disabled"
    ></creationAssembly2>
  </div>
</template>
    <script>
import creationAssembly1 from "@/components/creation_assembly1.vue";
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    creationAssembly1,
    creationAssembly2,
  },
  data() {
    return {
      title: "关键词提取",
      content1: {
        title: "关键提取",
        placeholder: this.pageContent.content,
      },
      //   请求信息
      contentInfo: {
        content: "",
      },
    };
  },
  created() {},
  methods: {
    getContent1(i) {
      this.contentInfo.content = i;
    },
    getInfo() {
      if (this.contentInfo.content == "") {
        this.$message({
          message: "请输入内容原文",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "关键提取",
          text: this.contentInfo.content,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "关键词提取",
        url: "gjctq",
      });
      return Arry;
    },
  },
};
</script>
    <style>

</style>
    