<template>
  <div id="assembly3">
    <div class="assembly_title">{{ content.title }}</div>
    <div class="assembly_lable">
      <div
        :class="lableIndex == i ? 'assembly_box assembly_ubox' : 'assembly_box'"
        v-for="(item, i) in content.list"
        :key="i"
        @click="getLable(i)"
      >
        {{ item }}
      </div>
    </div>

    <div class="strength">
      <div>改写强度</div>
      <div class="strength1">
        <div class="strength_reduce" @click="numChang(1)">-</div>
        <div>{{ num }}</div>
        <div class="strength_add"  @click="numChang(2)">+</div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    content: {
      type: Object,
      default: {},
    },
    //是否禁用
    disabled:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lableIndex: 0,
      num: "1",
    };
  },
  methods: {
    getLable(i) {
      if(this.disabled){
        return
      }
      this.lableIndex = i;
      this.$emit("getType", this.content.list[i]);
    },
    numChang(i){
      if(this.disabled){
        return
      }
        if(i == 1){
            if(this.num == 1){
                return
            }
            this.num-- 
        }
        if(i == 2){
            if(this.num == 5){
                return
            }
            this.num++
        }
        this.$emit("getNum", this.num);
    }
  },
};
</script>
  <style>
#assembly3 {
  position: relative;
}
#assembly3 .assembly_title {
  font-size: 1.2rem;
  color: #333;
  padding: 1rem 0;
}
.assembly_lable {
  display: flex;
  flex-wrap: wrap;
}
.assembly_box {
  font-size: 1.3rem;
  padding: 0.8rem 1.2rem;
  border-radius: 0.5rem;
  color: #799097;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
  border: 1px solid #799097;
  cursor: pointer;
}
.assembly_ubox {
  color: #ffffff;
  background: #409eff;
  border: 1px solid #409eff;
}
.strength{
    padding: 0.8rem 0;
    cursor: pointer;
}
.strength,
.strength1 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #799097;
}
.strength1{
    margin-left: 2rem;
    width: 8rem;
    justify-content: space-between;
    border: 1px solid #dcdcdc;
    color: #000;
}
.strength_reduce,
.strength_add{
    width: 2rem;
    text-align: center;
    font-size: 2rem;
    background: #dcdcdc;
}
</style>