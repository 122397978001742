<template>
  <div>
    <div class="creation_main_title">{{ title }}</div>

    <creationAssembly2
      :content="content1"
      @getContent="getContent1"
      :disabled="disabled"
    ></creationAssembly2>
    <creationAssembly2
      :content="content2"
      @getContent="getContent2"
      :disabled="disabled"
      inputType="number"
    ></creationAssembly2>

    <creationAssembly1
      :content="content"
      @getType="getType"
      :disabled="disabled"
    ></creationAssembly1>
  </div>
</template>
    <script>
import creationAssembly1 from "@/components/creation_assembly1.vue";
import creationAssembly2 from "@/components/creation_assembly2.vue";
export default {
  props: {
    pageContent: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    creationAssembly1,
    creationAssembly2,
  },
  data() {
    return {
      title: "电影评论",
      content: {
        title: "风格", //标题
        placeholder: "请输入自定义类型", // 文本框提示
        list: this.pageContent.style, //标签列表
      },
      content1: {
        title: "电影名称",
        placeholder: this.pageContent.movie,
      },
      content2: {
        title: "文本字数",
        placeholder: this.pageContent.text_lenth,
      },
      //   请求信息
      contentInfo: {
        style: "",
        movie: "",
        length: "",
      },
    };
  },
  created() {
    this.contentInfo.style = this.pageContent.style[0];
  },
  methods: {
    getType(i) {
      this.contentInfo.style = i;
    },
    getContent1(i) {
      this.contentInfo.movie = i;
    },
    getContent2(i) {
      this.contentInfo.length = i;
    },
    getInfo() {
      if (this.contentInfo.movie == "") {
        this.$message({
          message: "请输入电影名称",
          type: "warning",
        });
        return;
      }
      if (this.contentInfo.length == "") {
        this.$message({
          message: "请输入文本字数",
          type: "warning",
        });
        return;
      }
      let Arry = [];
      let detailInfo = [
        {
          title: "电影名称",
          text: this.contentInfo.movie,
        },
        {
          title: "文本字数",
          text: this.contentInfo.length,
        },
        {
          title: "风格",
          text: this.contentInfo.style,
        },
      ];
      Arry.push(this.contentInfo);
      Arry.push(detailInfo);
      Arry.push({
        text: "电影评论",
        url: "dypl",
      });
      return Arry;
    },
  },
};
</script>
    <style>

</style>
    