<template>
  <div id="result_content">
    <div class="result_content">
      <div class="result_content_title">{{ content[2].text }}</div>
      <div class="result_text" v-for="(item,i) in content[1]" :key="i">
        <div v-if="item.text !== ''" class="result_text1">{{ item.title }}:</div>
        <div v-if="item.text !== ''" class="result_text2">{{ item.text }}</div>
      </div>
    </div>
    <div style="margin: 1rem;">
      <anwserText v-if="!his" :content="anwser" @anwserOver="anwserOver" ref="anwserText"></anwserText>
      <div class="anwser_text" v-else  v-html="anwser"></div>
    </div>
  </div>
</template>
<script>
import anwserText from "@/components/anwser_text.vue";
export default {
  components:{
    anwserText
  },
  props:{
    content: {
      type: Array,
      default: [],
    },
    anwser:{
      type: String,
      default: '',
    },
    his:{
      type: Boolean,
      default:false,
    }
  },
  watch:{
    anwser(newVal, oldVal){
      // console.log(newVal == '','重写')
      if(newVal == ''){
        this.$refs.anwserText.rewriting()
      }
    }
  },
  created(){
  //  console.log(this.his)
  },
  data() {
    return {
      // anwserText:this.anwser,
    };
  },

  methods:{
    over() {
      this.$refs.anwserText.anwserOver();
    },
    anwserOver(){
      this.$emit("anwserOver");
    },
  }
};
</script>
<style>
#result_content {
  background: #ffffff;
  height: 80vh;
  overflow: auto;
}
.result_content{
  border-bottom: 1px solid #dfdfdf;
}
.result_content_title {
  font-size: 1.5rem;
  color: #799097;
  padding: 1rem;
}
.result_text {
  padding: 0 1rem;
  display: flex;
}
.result_text1 {
  font-size: 1.2rem;
  min-width: 6rem;
  margin-bottom: 0.5rem;
}
.result_text2 {
  font-size: 1.2rem;
  color: #409eff;
}
</style>