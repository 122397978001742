import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = process.env.VUE_APP_BASE_API


// get请求
export default {
  // 获取验证码
  GetCode(params) {
    return http.post(`${resquest}auth/app/sms/v2`, params)
  },
  // 手机登录
  loginPhone(params) {
    return http.post(`${resquest}auth/app/login/sms/pc`, params)
  },
  // 获取个人信息
  getUserinfo(params) {
    console.log(`${resquest}assistant/app/atuser/userinfo`)
    return http.get(`${resquest}assistant/app/atuser/userinfo`, params)
  },
  // 退出登录
  logout(params) {
    return http.delete(`${resquest}auth/app/logout`, params)
  },
  // 微信扫码登录
  loginWx(params) {
    return http.post(`${resquest}auth/app/login/wx/pc`, params)
  },

  // ai对话
  // 对话历史记录
  sessionList(params) {
    return http.get(`${resquest}assistant/app/session/list`, params)
  },
  // 删除聊天记录
  deleteSession(params) {
    return http.post(`${resquest}assistant/app/session/delete`, params)
  },
  // 创建会话
  createSession(params) {
    return http.get(`${resquest}assistant/app/session/create`, params)
  },
  // 会话内容
  contentSession(params) {
    return http.get(`${resquest}assistant/app/session/content`, params)
  },
  //免费次数
  degree(params) {
    return http.get(`${resquest}assistant/app/degree`, params)
  },
  // vip
  // 获取vip价格
  vipPrice(params) {
    return http.get(`${resquest}assistant/public/member/price`, params)
  },
  // 获取体验vip价格
  experienceVipPrice(params) {
    return http.get(`${resquest}assistant/public/experience/price`, params)
  },
  // apk下载
  getApk(params) {
    return http.get(`${resquest}system/public/apk`, params)
  },


  // 首页 写作，会员
  getHome(params) {
    return http.get(`${resquest}assistant/public/template/category/v2`, params)
  },
  // 办公列表
  getWork(params) {
    return http.get(`${resquest}assistant/public/template/work`, params)
  },
  // 获取文案提示词
  getPrompt(params) {
    return http.get(`${resquest}system/public/app/prompt/` + params)
  },
  // 获取问题列表
  getHistory(params) {
    return http.get(`${resquest}assistant/app/question/log/`, params)
  },
  //删除问题
  deluQestion(params) {
    return http.delete(`${resquest}assistant/app/question/log/` + params)
  },
  //顾问列表
  getAdviser(params) {
    return http.get(`${resquest}assistant/public/adviser/list`, params)
  },
   //顾问历史记录
   getAdviserlist(params) {
    return http.get(`${resquest}assistant/app/session/adviser/list`, params)
  },
  

  // 提问
  // getPrompt(params) {
  //   return http.post(`${resquest}assistant/app/question/stream/` + params)
  // },

}