<template>
  <div id="result_history">
    <div class="result_history">
      <div class="result_history_box" v-for="(item, i) in historyList" :key="i">
        <div class="result_history_title" @click="details(item)">
          {{ item.type }}
        </div>
        <div class="result_history_text" @click="details(item)">
          {{ item.answer }}
        </div>
        <div class="result_history_btn">
          <i @click="del(item.id)" class="el-icon-delete"></i>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="60%">
      <div class="popup">
        <div class="popup_title">{{ title }}</div>
        <div class="popup_text" v-html="answer"></div>
        <div style="height: 4rem"></div>
        <div class="popup_btn">
          <div class="popup_btn1" @click="copy">复制</div>
          <div class="popup_btn1" @click="close">关闭</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      index: 0,
      historyList: [],
      dialogVisible: false,
      answer: "",
      title: "",
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    // 获取历史记录
    getHistory() {
      api
        .getHistory({
          pageSize: 50,
        })
        .then((res) => {
          // console.log(res.data)
          if (res.code == 200) {
            this.historyList = res.data;
          }
        });
    },
    // 详情
    details(i) {
      // console.log(i.answer);
      this.answer = i.answer.replace(/\n/g, "<br/>");
      this.title = i.type;
      this.dialogVisible = true;
      // this.$emit("details", i,true);
    },
    close() {
      this.dialogVisible = false;
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.answer);
        this.$message({
          message: "复制成功",
          type: "success",
        });
      } catch (err) {
        this.$message.error("复制失败");
      }
    },
    // 删除
    del(id) {
      this.$confirm("是否删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.deluQestion(id).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.historyList = this.historyList.filter((item) => {
                if (item.id !== id) {
                  return item;
                }
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style>
#result_history {
  height: 78vh;
  overflow: auto;
  margin-top: 1rem;
}
.result_history {
  display: flex;
  flex-wrap: wrap;
}
.result_history_box {
  position: relative;
  width: 9rem;
  height: 8rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.result_history_title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.result_history_text {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* 显示的行数，可以根据需要修改 */
  overflow: hidden;
  text-overflow: ellipsis;
}
.result_history_btn {
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  display: flex;
  justify-content: end;
}
.el-icon-delete {
  margin-top: 0.8rem;
  font-size: 1.3rem;
}
/* #result_history .el-dialog{
  background: #FFFFFF !important;
} */
#result_history .el-dialog {
  padding-top: 10px !important;
  text-align: center !important;
  /* height: 78vh !important; */
  overflow: auto;
}
.popup {
  /* overflow: auto;
  padding: 3rem;
  padding-top: 0; */
}
.popup_title {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  text-align: start;
  padding-left: 3rem;
}

.popup_text {
  height: 60vh;
  overflow: auto;
  font-size: 1.2rem;
  color: #000;
  text-align: start;
  overflow: auto;
  padding: 3rem;
}
.popup_btn {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: end;
  width: 100%;
  padding-bottom: 1rem;
  background: #fff;
}
.popup_btn .popup_btn1 {
  /* width: 5rem; */
  background: #409eff;
  margin-right: 2rem;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
</style>