<template>
  <div id="writing">
    <div v-if="details">
      <writingHome
        title="热门创作"
        :list="hotsList"
        @change="change"
      ></writingHome>
      <writingHome
        title="会员专区"
        :list="vipList"
        :vip="true"
        @change="change"
      ></writingHome>
      <writingHome
        title="工作办公"
        :list="workList"
        @change="change"
        width="4rem"
      ></writingHome>
    </div>
    <div v-else class="details">
      <writeLeftlist
        :leftData="leftData"
        @black="black"
        @changType="changType"
        ref="Leftlist"
      ></writeLeftlist>
      <creation
        @getInfo="getInfo"
        ref="creation"
        :initialType="initialType"
      ></creation>
      <result
        :requestData="requestData"
        ref="result"
        @anwserOver="anwserOver"
        :empty="empty"
      ></result>
    </div>
  </div>
</template>

<script>
import writingHome from "@/components/writing_home.vue";
import writeLeftlist from "@/components/write_left_list.vue";
import creation from "@/components/creation.vue";
import result from "@/components/result.vue";
import api from "@/api/api";
import data from "@/assets/data";
export default {
  components: {
    writingHome,
    writeLeftlist,
    creation,
    result,
  },
  data() {
    return {
      details: true,
      requestData: [], //请求数据
      hotsList: data.data.homeHot, //热门列表
      vipList: [], // vip列表
      workList: [], //办公列表
      leftData: {}, //左边初始状态
      initialType: "", //初始type
      empty: true,
      remArry: [{ type: "aigw" }, { type: "pcdnb" }],
    };
  },
  created() {
    this.getHome();
  },
  methods: {
    // 获取，首页，办公，vip
    getHome() {
      api.getHome().then((res) => {
        if (res.code == 200) {
          this.hotsList = this.hotsList.concat(this.removeDuplicates(this.remArry, res.data.hots));
          this.vipList = this.removeDuplicates(this.remArry, res.data.vip);
          localStorage.setItem("hotsList", JSON.stringify(this.hotsList));
          localStorage.setItem("vipList", JSON.stringify(this.vipList));
          // console.log(this.hotsList)
        }
      });
      api.getWork().then((res) => {
        if (res.code == 200) {
          this.workList = this.removeDuplicates(this.remArry, res.data);
          localStorage.setItem("workList", JSON.stringify(this.workList));
        }
      });
    },
    // 删除数组中元素
    removeDuplicates(arr1, arr2) {
      const type = arr1.map((item) => item.type);
      return arr2.filter((item) => !type.includes(item.type));
    },
    // 返回
    black() {
      this.details = true;
    },
    // 切换
    change(item) {
      // console.log(item.item)
      this.details = false;
      this.leftData = item;
      this.empty = false;
      this.initialType = item.item.type;
    },
    getInfo(item) {
      // console.log('请求' ,item)
      this.requestData = item;
      this.$refs.result.changeTop(0, item, false);
    },
    // 左边切换
    changType(i) {
      this.$refs.creation.changeType(i);
      this.$refs.result.changeTop(0, [], false);
    },
    anwserOver() {
      this.$refs.creation.disallow();
    },
  },
};
</script>

<style>
#writing {
  overflow: auto;
  height: 85%;
  margin-top: 2rem;
}

.details {
  width: 128rem;
  background: #f3f7ff;
  height: 98%;
  display: flex;
  justify-content: space-between;
}
</style>