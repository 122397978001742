<template>
    <div>
      <div class="creation_main_title">{{ title }}</div>
  
      <creationAssembly2
        :content="content"
        @getContent="getContent1"
        :maxNum="1000"
        :disabled="disabled"
      ></creationAssembly2>
    </div>
  </template>
            <script>
  import creationAssembly2 from "@/components/creation_assembly2.vue";
  export default {
    props: {
      pageContent: {
        type: Object,
        default: {},
      },
      disabled: {
      type: Boolean,
      default: false,
    },
    },
    components: {
      creationAssembly2,
    },
    data() {
      return {
        title: "整改报告",
  
        content: {
          title: "整改问题",
          placeholder: this.pageContent.title,
        },
        //   请求信息
        contentInfo: {
            question: "",
        },
      };
    },
    created() {},
    methods: {
      getContent1(i) {
        this.contentInfo.question = i;
      },
      getInfo() {
        if (this.contentInfo.question == "") {
          this.$message({
            message: "请输入整改问题",
            type: "warning",
          });
          return;
        }
        let Arry = [];
        let detailInfo = [
          {
            title: "整改问题",
            text: this.contentInfo.question,
          },
        ];
        Arry.push(this.contentInfo);
        Arry.push(detailInfo);
        Arry.push({
          text: "整改报告",
          url: "zgbg",
        });
        return Arry;
      },
    },
  };
  </script>
            <style>
  .creation_main_title {
    font-size: 1.5rem;
    color: #799097;
    padding-bottom: 1rem;
    border-bottom: 1px solid #799097;
  }
  </style>
            