const pagelist =['xhs',
    "ggwa",
    "xxs",
    "xzw",
    "gzh",
    "zhhd",
    "wzrs",
    "spjb",
    "dypl",
    "gjctq",
    "lxbg",
    "yjg",
    "znfy",
    "wbzy",
    "wgxx",
    "xmtxz",
    "jasj",
    "gwxz",
    "gzjh",
    "hychfa",
    "gzhb",
    "pptdg",
    "htmb",
    "zbs",
    "khzj",
    "zgbg",
    "zczj",
    "hyjy",
    "yjya",
    "xdth",
    "gzzj",
    "fxbg",
    "hdfa",
    "pxzj",
    "jhxd",
    "ggc",
    "zngx",
    "xxwg",
    "xwz",
    "ycgx"]

export  { pagelist };