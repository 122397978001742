<template>
    <div>
      <div class="creation_main_title">{{ title }}</div>
      
      <creationAssembly1
        :content="content1"
        @getType="getType1"
        :disabled="disabled"
      ></creationAssembly1>
      
      <creationAssembly1
        :content="content2"
        @getType="getType2"
        :disabled="disabled"
      ></creationAssembly1>

      <creationAssembly2
        :content="content"
        @getContent="getContent1"
        :disabled="disabled"
      ></creationAssembly2>

      <creationAssembly2
        :content="content3"
        @getContent="getContent2"
        :disabled="disabled"
      ></creationAssembly2>
    </div>
  </template>
      <script>
  import creationAssembly1 from "@/components/creation_assembly1.vue";
  import creationAssembly2 from "@/components/creation_assembly2.vue";
  export default {
    props: {
      pageContent: {
        type: Object,
        default: {},
      },
    disabled: {
      type: Boolean,
      default: false,
    },
    },
    components: {
      creationAssembly1,
      creationAssembly2,
    },
    data() {
      return {
        title: "教案设计",
  
        content: {
          title: "关键词",
          placeholder: this.pageContent.keyword,
        },
        content3: {
          title: "文本字数",
          placeholder: this.pageContent.text_lenth,
        },
        content1: {
          title: "教育阶段", //标题
          placeholder: "请输入自定义教育阶段", // 文本框提示
          list: this.pageContent.stage, //标签列表
        },
        content2: {
          title: "科目", //标题
          placeholder: "请输入自定义科目", // 文本框提示
          list: this.pageContent.course, //标签列表
        },
        //   请求信息
        contentInfo: {
          stage: "",
          course: "",
          keyword:'',
          length: "",
        },
      };
    },
    created() {
      // console.log(this.pageContent)
      this.contentInfo.course = this.pageContent.course[0];
      this.contentInfo.stage = this.pageContent.stage[0];
    },
    methods: {
      getType1(i) {
        this.contentInfo.stage = i;
      },
      getType2(i) {
        this.contentInfo.course = i;
      },
      getContent1(i) {
        this.contentInfo.keyword = i;
      },
      getContent2(i) {
        this.contentInfo.length = i;
      },
      getInfo() {
        if (this.contentInfo.keyword == "") {
          this.$message({
            message: "请输入关键词",
            type: "warning",
          });
          return;
        }
        if (this.contentInfo.length == "") {
          this.$message({
            message: "请输入文本字数",
            type: "warning",
          });
          return;
        }
        let Arry = [];
        let detailInfo = [
          {
            title: "教育阶段",
            text: this.contentInfo.stage,
          },
          {
            title: "科目",
            text: this.contentInfo.course,
          },
          {
            title: "关键词",
            text: this.contentInfo.keyword,
          },
          {
            title: "文本字数",
            text: this.contentInfo.length,
          },
        ];
        Arry.push(this.contentInfo);
        Arry.push(detailInfo);
        Arry.push({
          text: "教案设计",
          url: "jasj",
        });
        return Arry;
      },
    },
  };
  </script>
      <style>
  .creation_main_title {
    font-size: 1.5rem;
    color: #799097;
    padding-bottom: 1rem;
    border-bottom: 1px solid #799097;
  }
  </style>
      