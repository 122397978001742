<template>
  <div>
    <div
      :class="
        hotIndex == item.id ? 'write_lable_box write_lable_ubox' : 'write_lable_box'
      "
      v-for="(item) in list"
      :key="item.id"
      @click="select(item)"
    >
      <div v-if="hotIndex == item.id" class="write_select"></div>
      <img :style="{width:width}"  :src="item.icon" alt="" />
      <div class="write_lable_text">{{ item.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    initialId:{
      type: Number,
      default: 0,
    },
    width:{
      type: String,
      default: '3rem',
    }
  },
  data() {
    return {
      hotIndex: this.initialId, //热门选中下标
    };
  },
  methods: {
    select(item) {
      this.hotIndex = item.id
      this.$emit("changeIndex",item);
    },
  },
};
</script>

<style>
.write_lable_box {
  font-size: 1.4rem;
  font-weight: 400;
  width: 90%;
  height: 4rem;
  line-height: 4rem;
  margin: 0 auto;
  border: 1px solid #e7f0ff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.write_lable_ubox {
  color: #0361ef;
}
.write_lable_box:hover {
  background: #9ac3ff;
}
.write_lable_box img {
  /* width: 3rem; */
  margin-right: 0.5rem;
}
.write_lable_text {
  width: 9rem;
  white-space: nowrap; /* 对于单行文本 */
  overflow: hidden;
  text-overflow: ellipsis; /* 显示省略号 */
}
.write_select {
  position: absolute;
  left: 0;
  width: 0.3rem;
  height: 100%;
  background: #409eff;
}
</style>